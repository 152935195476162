import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import DashboardNavbar from "../components/DashboardNavbar";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { db } from "../firebase";
import { doc, getDoc, updateDoc, Timestamp } from "firebase/firestore";
import { AnimatePresence, motion, useAnimate } from "framer-motion";
import exampledata from "../data/exampledata";
import Popup from "../components/Popup";
import { isValid } from "date-fns";
import Tip from "../components/Tip";
import { MMDAnimationHelper } from "three/examples/jsm/Addons.js";

class MinHeap {
    constructor() {
        this.heap = [];
    }

    getParentIndex(index) {
        return Math.floor((index - 1) / 2);
    }

    getLeftChildIndex(index) {
        return 2 * index + 1;
    }

    getRightChildIndex(index) {
        return 2 * index + 2;
    }

    swap(index1, index2) {
        [this.heap[index1], this.heap[index2]] = [this.heap[index2], this.heap[index1]];
    }

    insert(element) {
        this.heap.push(element);
        this.heapifyUp(this.heap.length - 1);
    }

    heapifyUp(index) {
        while (index > 0 && this.heap[index].mark > this.heap[this.getParentIndex(index)].mark) {
            this.swap(index, this.getParentIndex(index));
            index = this.getParentIndex(index);
        }
    }

    remove() {
        if (this.heap.length === 0) {
            return null;
        }

        const root = this.heap[0];
        if (this.heap.length === 1) {
            this.heap.pop();
        } else {
            this.heap[0] = this.heap.pop();
            this.heapifyDown(0);
        }
        return root;
    }

    heapifyDown(index) {
        let smallest = index;

        const leftChildIndex = this.getLeftChildIndex(index);
        const rightChildIndex = this.getRightChildIndex(index);

        if (leftChildIndex < this.heap.length && this.heap[leftChildIndex].mark > this.heap[smallest].mark) {
            smallest = leftChildIndex;
        }

        if (rightChildIndex < this.heap.length && this.heap[rightChildIndex].mark > this.heap[smallest].mark) {
            smallest = rightChildIndex;
        }

        if (smallest !== index) {
            this.swap(index, smallest);
            this.heapifyDown(smallest);
        }
    }

    size() {
        return this.heap.length;
    }

    peek() {
        return this.heap[0];
    }

    check() {
        for (let i = 0; i < this.heap.length; i++) {
            console.log(this.heap[i].mark);
        }
    }
} 

function findKSmallestByMarkIndexes(arrayData, k) {
    const array = JSON.parse(JSON.stringify(arrayData));
    if (k === 0) return [];
    const minHeap = new MinHeap();

    for (let i = 0; i < array.length; i++) {
        if (array[i].mark === "") {
            array[i].mark = 0;
        }
        minHeap.insert({ index: i, mark: Number(array[i].mark) });
    }

    while (minHeap.size() > k) {
        minHeap.remove();
    }
    
    const result = [];
    while (minHeap.size() > 0) {
        result.push(minHeap.remove().index);
    }

    return result.reverse(); 
}

export function exists(something) {
    if (something || (typeof something === 'number' && something == 0)) {
        return true;
    }
    return false;
}

export function formatNumber(num) {
    let strNum = num.toString();
    
    if (strNum.includes('.')) {
        let [integerPart, fractionalPart] = strNum.split('.');
        
        if (fractionalPart.length > 2) {
            return num.toFixed(2);
        }
        
        return parseFloat(`${integerPart}.${fractionalPart}`);
    } else {
        return num;
    }
}


export default function CoursePage() {
    const [userID, setUserID] = useState(null);

    // all edit states
    const [editHeader, setEditHeader] = useState(false);
    const [editSection, setEditSection] = useState([]);     // index of all sections that are on edit mode
    const [addNewSection, setAddNewSection] = useState(false);
    const [addNewAssessment, setAddNewAssessment] = useState([]);   // indexes of all sections that are toggled

    // all of the course data, for updating the database
    const [allCourseData, setAllCourseData] = useState({});

    // actual course data that will be used to render stuff and calculate stuff
    const [courseData, setCourseData] = useState({});

    // temporary course data that will be made to make edits before pushing this to the actual course data
    const [tempCourseData, setTempCourseData] = useState({});

    // course name when editing the course name
    const [courseName, setCourseName] = useState("");

    // newAssessmentItem is the data for adding a new assessment item
    const [newAssessmentItem, setNewAssessmentItem] = useState({});

    // sectionName is the name of the newSection when trying to add a new section
    const [sectionName, setSectionName] = useState("");

    // editSectionName is the name of the old section when editing a previous section name.
    // keys are the index of the section, value is the new section name corresponding to that index.
    const [editSectionName, setEditSectionName] = useState({});

    // open the delete course warning
    const [deleteCourse, setDeleteCourse] = useState(false);

    // editAssessment is the indexes of the assessment that is currently being edited. 
    // format: [indexOfSection, indexOfAssessment]
    const [editAssessment, setEditAssessment] = useState([]);

    // editAssessmentItem is the data of the edited assessments. format is a nested object as follows:
    // { indexOfSection: { indexOfAssessment: { assessmentData }}}
    const [editAssessmentItem, setEditAssessmentItem] = useState({});

    // total weightPossibleChange by editing/adding/deleting assessments. similarly with weightAchievedChange
    const [, setWeightPossibleChange] = useState(0);
    const [, setWeightAchievedChange] = useState(0);

    // previouslyEdited is what the user last edited before the current edit for editing/adding assessments
    // format: [previously edited character, previously edited field name]
    const [previouslyEdited, setPreviouslyEdited] = useState(['', '']);

    // showTooltipsMessage is the initial "btw you can hover/long press on mobile to show tooltips" thing or nah
    const [showTooltipsMessage, setShowTooltipsMessage] = useState(false);

    // dont show again option is ticked or not. true meaning dont show again.
    const [dontShowAgain, setDontShowAgain] = useState(false);

    // used for tips and hover
    const someDefaultState = {
        shareIcon: false,
        editHeaderIcon: false,
        editCourseName: false,
        deleteCourseIcon: false,
        editCredits: false,
        editTarget: false,
        remainingBufferZone: false,
        editSectionIcon: false,
        editSectionName: false,
        editDrops: false,
        deleteSectionIcon: false,
        editAssessmentIcon: false,
        editAssessmentName: false,
        editDueDate: false,
        editMark: false,
        editWeightAchieved: false,
        editWeightPossible: false,
        deleteAssessmentIcon: false,
        createAssessmentName: false,
        createAssessmentDueDate: false,
        createAssessmentMark: false,
        createAssessmentWeightAchieved: false,
        createAssessmentWeightPossible: false,
        createSectionName: false
    }

    // drops holds the data for the sections with the drops. the format is as follows:
    // { indexOfSection: numberOfDrops }
    const [drops, setDrops] = useState({});

    // tips is an object with all possible tips, usually only one of them can be true at a time
    // they may all be false. the one that is true denotes which tip is currently being displayed
    const [tips, setTips] = useState(someDefaultState);

    // hover is an object with the same properties as above, it just denotes whether the mouse
    // is hovering over something or not.
    const [hover, setHover] = useState(someDefaultState);

    // coords of the mouse. used to position tooltips. [x, y] in pixels.
    const [coords, setCoords] = useState([0, 0]);

    // coords of the touch. used to position tooltips. [x, y] in pixels
    const [touchPos, setTouchPos] = useState([0, 0]);
    
    function setHoverToDefault() {
        setHover(someDefaultState);
    }

    function setTipsToDefault() {
        setTips(someDefaultState);
    }

    const navigate = useNavigate();
    const { index } = useParams();

    const [scope, animate] = useAnimate();

    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setViewportWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const auth = getAuth();
    useEffect(() => {
        onAuthStateChanged(auth, (user) => {
            // if the user is signed in 
            if (user) {
                const uid = user.uid;
                setUserID(uid);
                const docRef = doc(db, "users", user.uid);
                getDoc(docRef)
                    .then((docSnap) => {
                        try {
                            const loadWATest = docSnap.data().courses[index].weightAchieved;
                        }
                        catch (error) {
                            navigate("/pageNotFound");
                        }
                        if (docSnap.exists() && docSnap.data().welcome) {
                            navigate("/welcome");
                        }
                        if (docSnap.exists() && !docSnap.data().welcome) {
                            setAllCourseData(docSnap.data().courses);
                            const showTooltips = docSnap.data().showTooltipMessage ? true : false;
                            setShowTooltipsMessage(showTooltips);
                            setCourseData(docSnap.data().courses[index]);
                            setTempCourseData(docSnap.data().courses[index]);
                        }
                    }).catch((error) => {
                        navigate("/error");
                    }
                );
            }
                
            // if the user is NOT signed in
            else {
                navigate("/");
            }
        });
    }, []);

    function openInNewTab(url) {
        window.open(url, '_blank', 'noopener,noreferrer');
    }

    const lastPage = JSON.parse(sessionStorage.getItem("lastPage"));

    // popup shts
    const [popup, setPopup] = useState({
        visible: false,
        text: "",
        image: ""
    });

    /**
     * 
     * @param {string} text 
     * @param {string} image 
     */
    function handlePopup(text, image) {
        if (!popup.visible) {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
            setTimeout(() => setPopup({
                visible: false,
                text: "",
                image: ""
            }), 3000);
        }
        else {
            setPopup(() => {
                return {
                    visible: true,
                    text: text,
                    image: image
                }
            });
        }
    }

    function handleDeleteCourse() {
        setDeleteCourse(true);
    }
    
    function handleConfirmDeleteCourse() {
        setAllCourseData(prevData => {
            let newData = prevData;

            newData.splice(index, 1);

            updateDB(newData);

            navigate("/dashboard");

            return JSON.parse(JSON.stringify(newData));
        });
    }
    
    function handleHeaderChange(event) {
        setTempCourseData(prevTempCourseData => {
            if (event.target.name === 'credits' && isNaN(Number(event.target.value))) {
                return prevTempCourseData;
            }
            else if (Number(event.target.value) != Number(event.target.value).toFixed(1)) {
                return prevTempCourseData;
            }
            else if (event.target.name === 'target' && (isNaN(Number(event.target.value)) || Number(event.target.value) > 100)) {
                return prevTempCourseData;
            }
            else {
                return {
                    ...prevTempCourseData,
                    [event.target.name]: event.target.value
                }
            }
        });
    }

    function handleEditCourseName(event) {
        setCourseName(prev => {
            const { name, value } = event.target;
            const courseRegex = /^[a-z0-9 ]*$/i;

            if (value.length > 10) {
                return prev;
            }
            else if (!courseRegex.test(value)) {
                return prev;
            }

            setTempCourseData(prevData => {
                return {
                    ...prevData,
                    name: value
                }
            });
            return value;
        });
    }

    function getPreviouslyEdited(name, prevEdit) {
        if (name === prevEdit[0]) {
            return prevEdit;
        }
        else if (prevEdit[0] !== "") {
            prevEdit[1] = prevEdit[0]
            prevEdit[0] = name;
            return prevEdit;
        }
        return [name, ""]
    }

    function slashReader(value) {
        let firstNum = "";
        let secondNum = "";
        let foundSlash = false
        for (let i = 0; i < value.length; i++) {
            if (foundSlash && value.charAt(i) !== '/') {
                secondNum += value.charAt(i);
            }
            else if (value.charAt(i) !== '/') {
                firstNum += value.charAt(i);
            }
            if (value.charAt(i) === '/') {
                foundSlash = true;
            }
        }

        return [firstNum, secondNum];
    }

    // how tf does this function work bruh skull emoji
    // whatever you do, DO NOT TOUCH IT
    function handleNewAssessmentChange(event, indexOfSection, indexOfAssessment) {
        setPreviouslyEdited(prevEdit => {
            if (event.target.name === prevEdit[0]) {
                return prevEdit;
            }
            else if (prevEdit[0] !== "") {
                prevEdit[1] = prevEdit[0]
                prevEdit[0] = event.target.name;
                return prevEdit;
            }
            return [event.target.name, ""]
        });

        setNewAssessmentItem(prev => {
            const { name, value } = event.target;
            
            const defaultAssessment = {
                name: "",
                mark: "",
                dueDate: "",
                weightAchieved: "",
                weightPossible: "",
                complete: false
            }

            const updatedAssessment = Object.keys(prev).length === 0 ? {
                ...defaultAssessment,
                [name]: value 
            } : {
                ...prev[indexOfSection],
                [name]: value
            };

            const lastEditedFieldData = getPreviouslyEdited(name, previouslyEdited);
            const lastEditedField = lastEditedFieldData[1];

            const slashRegex = /^[0-9]*[.]?[0-9]*[/][0-9]*[.]?[0-9]*$/;
            const markRegex = /^[0-9]*[.]?[0-9]*$/;

            // verification shts starts here
            // ensuring that the name is less than 20 characters
            if (name === 'name' && value.length > 20) {
                return prev;
            }

            // ENSURING THAT MARK IS NUMERIC and between 0 and 100
            if (name === 'mark' && !slashRegex.test(value) && !markRegex.test(value)) {
                return prev;
            }
            
            if (name === 'mark' && Number(value) < 0 || Number(value) > 100) {
                return prev;
            }

            // ENSURING THAT WEIGHTACHIEVED IS NUMERIC
            if (name === 'weightAchieved' && (isNaN(Number(value)) || Number(value) < 0)) {
                return prev;
            }

            //  ensuring that weight achieved is less than weight possible
            if (name === 'weightAchieved' && (prev[indexOfAssessment] && (Number(value) > prev[indexOfAssessment].weightPossible))) {
                return prev;
            }

            // ENSURING THAT WEIGHTPOSSIBLE IS NUMERIC and between 0 and 100
            if (name === 'weightPossible' && (isNaN(Number(value)) || (value === "0" && Number(value) <= 0))) {
                return prev;
            }

            // ensuring that the name of the assessment only consist of allowable characters
            const nameRegex = /^[0-9a-z ._-]*$/i;
            if (name === 'name' && !nameRegex.test(value)) {
                return prev;
            }

            let dueDate = "";
            if (name === 'dueDate') {
                const gmtDate = new Date(value);
                let timezoneDiff = gmtDate.getTimezoneOffset() * 60 * 1000;
                let localDate = new Date(gmtDate.getTime() + timezoneDiff);
                dueDate = Timestamp.fromDate(localDate);
            }

            if (dueDate) {
                updatedAssessment.dueDate = dueDate;
            }

            // auto calculation shts start here
            // if u type into mark (but not slash regex) and (wa or wp) exists
            if (name === 'mark' && !slashRegex.test(value) && (exists(updatedAssessment.weightAchieved) || exists(updatedAssessment.weightPossible))) {
                if ((lastEditedField === 'weightPossible' && exists(updatedAssessment.weightPossible)) || (lastEditedField === 'weightAchieved' && exists(updatedAssessment.weightPossible))) {
                    const wa = Number(Number(updatedAssessment.weightPossible) * (Number(updatedAssessment.mark) / 100));
                    updatedAssessment.weightAchieved = wa;
                }
            }

            // if the mark field is valid slash regex and (wa or wp) exists
            else if (name === 'mark' && slashRegex.test(value) && (exists(updatedAssessment.weightAchieved) || exists(updatedAssessment.weightPossible))) {

                const [ firstNum, secondNum ] = slashReader(value);

                if (secondNum !== '' && secondNum !== '0') {
                    const newMark = Number((Number(firstNum) / Number(secondNum)) * 100);
                    if ((lastEditedField === 'weightPossible' && exists(updatedAssessment.weightPossible)) || (lastEditedField === 'weightAchieved' && exists(updatedAssessment.weightPossible))) {
                        const wa = Number(Number(updatedAssessment.weightPossible) * (Number(newMark) / 100));
                        updatedAssessment.weightAchieved = wa;
                    }
                    else if (!lastEditedField) {
                        const wa = Number(Number(updatedAssessment.weightPossible) * (Number(newMark) / 100));
                        updatedAssessment.weightAchieved = wa;
                    }
                }
            }

            // if u type into wa and (mark or wp) exists
            else if (name === 'weightAchieved' && (exists(updatedAssessment.mark) || exists(updatedAssessment.weightPossible))) {
                if ((lastEditedField === 'weightPossible' && exists(updatedAssessment.weightPossible)) || ((lastEditedField === 'mark') && exists(updatedAssessment.weightPossible))) {
                    const mark = (Number(Number(updatedAssessment.weightAchieved) / Number(updatedAssessment.weightPossible)) * 100);
                    updatedAssessment.mark = mark;
                }
            }

            // if u type into wp (but not slash regex) and (mark or wa) exists
            else if (name === 'weightPossible' && !slashRegex.test(updatedAssessment.mark) && exists(updatedAssessment.weightPossible) && (exists(updatedAssessment.mark) || exists(updatedAssessment.weightAchieved))) {
                if (lastEditedField === 'weightAchieved') {
                    const mark = (Number(Number(updatedAssessment.weightAchieved) / Number(updatedAssessment.weightPossible)) * 100);
                    updatedAssessment.mark = mark;
                }
                else if (lastEditedField === 'mark') {
                    const wa = Number(Number(updatedAssessment.weightPossible) * (Number(updatedAssessment.mark) / 100));
                    updatedAssessment.weightAchieved = wa;
                }
            }

            // if the mark field is valid slash regex and ur typing into wp, find the wa
            else if (name === 'weightPossible' && slashRegex.test(updatedAssessment.mark) && exists(updatedAssessment.weightPossible) && (exists(updatedAssessment.mark) || exists(updatedAssessment.weightAchieved))) {
                const [ firstNum, secondNum ] = slashReader(updatedAssessment.mark);
                const mark = Number((Number(firstNum) / Number(secondNum)) * 100);
                if (lastEditedField === 'weightAchieved') {
                    updatedAssessment.mark = mark;
                }
                else if (lastEditedField === 'mark') {
                    const wa = Number(Number(updatedAssessment.weightPossible) * (Number(mark) / 100));
                    updatedAssessment.weightAchieved = wa;
                }
            }

            else if (name === 'weightPossible' && !exists(updatedAssessment.weightPossible)) {
                if (lastEditedField === 'weightAchieved' && updatedAssessment.mark) {
                    updatedAssessment.mark = "";
                }
                else if (lastEditedField === 'mark' && exists(updatedAssessment.weightAchieved)) {
                    updatedAssessment.weightAchieved = "";
                }
            }

            const newState = {
                ...prev,
                [indexOfSection]: updatedAssessment
            }

            setTempCourseData(prevTempData => {
                const newSections = [...prevTempData.sections];
                newSections[indexOfSection].data[indexOfAssessment] = updatedAssessment;

                const newTempData = {
                    ...prevTempData,
                    sections: newSections
                }
                return newTempData;
            });

            setWeightPossibleChange(prevWPC => {
                let newWPC = prevWPC;
                if (exists(updatedAssessment.weightAchieved)) {
                    if (newAssessmentItem[indexOfSection] && name === 'weightPossible') {
                        newWPC -= isNaN(Number(newAssessmentItem[indexOfSection].weightPossible)) ? 0 : Number(newAssessmentItem[indexOfSection].weightPossible);
                        newWPC += Number(updatedAssessment.weightPossible);
                    }
                    if (newAssessmentItem[indexOfSection] && name === 'weightAchieved' && !newAssessmentItem[indexOfSection].weightAchieved) {
                        newWPC += Number(updatedAssessment.weightPossible);
                    }
                }
                if (name === 'weightAchieved' && !updatedAssessment.weightAchieved) {
                    newWPC -= Number(updatedAssessment.weightPossible);
                }
                if (name === 'weightPossible' && !updatedAssessment.weightPossible && newAssessmentItem[indexOfSection].weightPossible) {
                    newWPC -= Number(newAssessmentItem[indexOfSection].weightPossible);
                }
                return newWPC;
            });

            setWeightAchievedChange(prevWAC => {
                let newWAC = prevWAC;
                if ((name !== 'name' && name !== 'dueDate') && newAssessmentItem[indexOfSection] && updatedAssessment.weightAchieved !== newAssessmentItem[indexOfSection]) {
                    newWAC -= isNaN(Number(newAssessmentItem[indexOfSection].weightAchieved)) ? 0 : Number(newAssessmentItem[indexOfSection].weightAchieved);
                    newWAC += Number(updatedAssessment.weightAchieved); 
                }
                if (!newAssessmentItem[indexOfSection]) {
                    newWAC += Number(updatedAssessment.weightAchieved);
                }
                return newWAC;
            });

            return newState;
            
        });
    }

    function handleEditAssessment(indexOfSection, indexOfAssessment) {
        setEditAssessment(prev => {
            const newState = [
                ...prev,
                [indexOfSection, indexOfAssessment]
            ];
            return newState;
        });

        setEditAssessmentItem(prev => {
            const newAssessment = courseData.sections[indexOfSection].data[indexOfAssessment];
            const newData = {
                ...prev[indexOfSection],
                [indexOfAssessment]: newAssessment
            }
            const newState = {
                ...prev,
                [indexOfSection]: newData
            }
            return newState;
        })
    }
    
    function handleEditAssessmentChange(event, indexOfSection, indexOfAssessment) {

        setPreviouslyEdited(prevEdit => {
            if (event.target.name === prevEdit[0]) {
                return prevEdit;
            }
            else if (prevEdit[0] !== "") {
                prevEdit[1] = prevEdit[0]
                prevEdit[0] = event.target.name;
                return prevEdit;
            }
            return [event.target.name, ""]
        });
        
        setEditAssessmentItem(prev => {
            const { name, value } = event.target;
            const lastEditedFieldData = getPreviouslyEdited(name, previouslyEdited);
            const lastEditedField = lastEditedFieldData[1];

            const slashRegex = /^[0-9]*[.]?[0-9]*[/][0-9]*[.]?[0-9]*$/;
            const markRegex = /^[0-9]*[.]?[0-9]*$/;

            // verification shts starts here
            // ensuring that the name is less than 20 characters
            if (name === 'name' && value.length > 20) {
                return prev;
            }

            // ENSURING THAT MARK IS NUMERIC and between 0 and 100
            if (name === 'mark' && !slashRegex.test(value) && !markRegex.test(value)) {
                return prev;
            }
            
            if (name === 'mark' && Number(value) < 0 || Number(value) > 100) {
                return prev;
            }

            // ENSURING THAT WEIGHTACHIEVED IS NUMERIC
            if (name === 'weightAchieved' && (isNaN(Number(value)) || Number(value) < 0)) {
                return prev;
            }

            //  ensuring that weight achieved is less than weight possible
            if (name === 'weightAchieved' && (prev[indexOfAssessment] && (Number(value) > prev[indexOfAssessment].weightPossible))) {
                return prev;
            }

            // ENSURING THAT WEIGHTPOSSIBLE IS NUMERIC and between 0 and 100
            if (name === 'weightPossible' && (isNaN(Number(value)) || (value === "0" && Number(value) <= 0))) {
                return prev;
            }

            // ensuring that the name of the assessment only consist of allowable characters
            const nameRegex = /^[0-9a-z ._-]*$/i;
            if (name === 'name' && !nameRegex.test(value)) {
                return prev;
            }

            let updatedAssessment = {
                ...prev[indexOfSection][indexOfAssessment],
                [event.target.name]: event.target.value
            }

            let dueDate = "";
            if (name === 'dueDate') {
                const gmtDate = new Date(value);
                let timezoneDiff = gmtDate.getTimezoneOffset() * 60 * 1000;
                let localDate = new Date(gmtDate.getTime() + timezoneDiff);
                dueDate = Timestamp.fromDate(localDate);
            }

            if (dueDate) {
                updatedAssessment.dueDate = dueDate;
            }

            // auto calculation shts start here
            // if u type into mark and (wa or wp) exists
            if (name === 'mark' && !slashRegex.test(value) && (exists(updatedAssessment.weightAchieved) || exists(updatedAssessment.weightPossible))) {
                if ((lastEditedField === 'weightPossible' && exists(updatedAssessment.weightPossible)) || (lastEditedField === 'weightAchieved' && exists(updatedAssessment.weightPossible))) {
                    const wa = Number(Number(updatedAssessment.weightPossible) * (Number(updatedAssessment.mark) / 100));
                    updatedAssessment.weightAchieved = wa;
                }
                else if (!lastEditedField) {
                    const wa = Number(Number(updatedAssessment.weightPossible) * (Number(updatedAssessment.mark) / 100));
                    updatedAssessment.weightAchieved = wa;
                }
            }

            // if the mark field is valid slash regex and (wa or wp) exists
            else if (name === 'mark' && slashRegex.test(value) && (exists(updatedAssessment.weightAchieved) || exists(updatedAssessment.weightPossible))) {

                const [ firstNum, secondNum ] = slashReader(value);

                if (secondNum !== '' && secondNum !== '0') {
                    const newMark = Number((Number(firstNum) / Number(secondNum)) * 100);
                    if ((lastEditedField === 'weightPossible' && exists(updatedAssessment.weightPossible)) || (lastEditedField === 'weightAchieved' && exists(updatedAssessment.weightPossible))) {
                        const wa = Number(Number(updatedAssessment.weightPossible) * (Number(newMark) / 100));
                        updatedAssessment.weightAchieved = wa;
                    }
                    else if (!lastEditedField) {
                        const wa = Number(Number(updatedAssessment.weightPossible) * (Number(newMark) / 100));
                        updatedAssessment.weightAchieved = wa;
                    }
                }
            }

            // if u type into wa and (mark or wp) exists
            else if (name === 'weightAchieved' && (exists(updatedAssessment.mark) || exists(updatedAssessment.weightPossible))) {
                if ((lastEditedField === 'weightPossible' && exists(updatedAssessment.weightPossible)) || (lastEditedField === 'mark') && exists(updatedAssessment.weightPossible)) {
                    const mark = (Number(Number(updatedAssessment.weightAchieved) / Number(updatedAssessment.weightPossible)) * 100);
                    updatedAssessment.mark = mark;
                }
                else if (!lastEditedField && exists(updatedAssessment.weightPossible)) {
                    const mark = (Number(Number(updatedAssessment.weightAchieved) / Number(updatedAssessment.weightPossible)) * 100);
                    updatedAssessment.mark = mark;
                }
            }

            // if u type into wp (but mark is not slash regex) and (mark or wa) exists
            else if (name === 'weightPossible' && !slashRegex.test(updatedAssessment.mark) && exists(updatedAssessment.weightPossible) && (exists(updatedAssessment.mark) || exists(updatedAssessment.weightAchieved))) {
                if (lastEditedField === 'weightAchieved') {
                    const mark = (Number(Number(updatedAssessment.weightAchieved) / Number(updatedAssessment.weightPossible)) * 100);
                    updatedAssessment.mark = mark;
                }
                else if (lastEditedField === 'mark') {
                    const wa = Number(Number(updatedAssessment.weightPossible) * (Number(updatedAssessment.mark) / 100));
                    updatedAssessment.weightAchieved = wa;
                }
            }

            // if the mark field is valid slash regex and ur typing into wp, find the wa
            else if (name === 'weightPossible' && slashRegex.test(updatedAssessment.mark) && exists(updatedAssessment.weightPossible) && (exists(updatedAssessment.mark) || exists(updatedAssessment.weightAchieved))) {
                const [ firstNum, secondNum ] = slashReader(updatedAssessment.mark);
                const mark = Number((Number(firstNum) / Number(secondNum)) * 100);
                if (lastEditedField === 'weightAchieved') {
                    updatedAssessment.mark = mark;
                }
                else if (lastEditedField === 'mark') {
                    const wa = Number(Number(updatedAssessment.weightPossible) * (Number(mark) / 100));
                    updatedAssessment.weightAchieved = wa;
                }
            }

            else if (name === 'weightPossible' && !exists(updatedAssessment.weightPossible)) {
                if (lastEditedField === 'weightAchieved' && updatedAssessment.mark) {
                    updatedAssessment.mark = "";
                }
                else if (lastEditedField === 'mark' && updatedAssessment.weightAchieved) {
                    updatedAssessment.weightAchieved = "";
                }
            }

            const newState = {
                ...prev,
                [indexOfSection]: {
                    ...prev[indexOfSection],
                    [indexOfAssessment]: updatedAssessment
                }
            }

            setTempCourseData(prevData => {
                const updatedSections = [...prevData.sections]
                updatedSections[indexOfSection].data[indexOfAssessment] = updatedAssessment;
                const newTempData = { ...prevData, sections: updatedSections };
                return newTempData;
            });

            setWeightPossibleChange(prevWPC => {
                let newWPC = prevWPC;
                if (exists(updatedAssessment.weightAchieved)) {
                    if (editAssessmentItem[indexOfSection][indexOfAssessment] && name === 'weightPossible') {
                        newWPC -= isNaN(Number(editAssessmentItem[indexOfSection][indexOfAssessment].weightPossible)) ? 0 : Number(editAssessmentItem[indexOfSection][indexOfAssessment].weightPossible);
                        newWPC += Number(updatedAssessment.weightPossible);
                    }
                    if (editAssessmentItem[indexOfSection][indexOfAssessment] && name === 'weightAchieved' && !editAssessmentItem[indexOfSection][indexOfAssessment].weightAchieved) {
                        newWPC += Number(updatedAssessment.weightPossible);
                    }
                    if (name === 'mark' && (!editAssessmentItem[indexOfSection][indexOfAssessment].weightAchieved)) {
                        newWPC += Number(updatedAssessment.weightPossible);
                    }
                }
                if (name === 'weightAchieved' && !updatedAssessment.weightAchieved) {
                    newWPC -= Number(updatedAssessment.weightPossible);
                }
                return newWPC;
            });

            setWeightAchievedChange(prevWAC => {
                let newWAC = prevWAC;
                if (updatedAssessment.weightAchieved !== editAssessmentItem[indexOfSection][indexOfAssessment]) {
                    newWAC -= isNaN(Number(editAssessmentItem[indexOfSection][indexOfAssessment].weightAchieved)) ? 0 : Number(editAssessmentItem[indexOfSection][indexOfAssessment].weightAchieved);
                    newWAC += Number(updatedAssessment.weightAchieved); 
                }
                return newWAC;
            });

            return newState;
        });
    }

    function handleSectionNameChange(event, index) {
        setSectionName(prev => {
            const next = event.target.value;
            const nextRegex = /^[0-9a-z ._-]*$/i;
            if (nextRegex.test(next) && next.length <= 17) {
                setTempCourseData(prev => {
                    const defaultSection = {
                        name: next,
                        drops: 0,
                        data: []
                    }

                    if (index > 0) {
                        prev.sections[index] = defaultSection;
                        return prev;
                    }
                    const newSections = [defaultSection];
                    const newState = {
                        ...prev,
                        sections: newSections
                    }

                    return newState;
                });
                return next;
            }
            return prev;
        });
    }

    function handleEditSectionNameChange(event, index) {
        setEditSectionName(prev => {
            const next = event.target.value;
            const nextRegex = /^[0-9a-z ._-]*$/i;
            if (nextRegex.test(next) && next.length <= 17) {
                setTempCourseData(prevData => {
                    const newSection = {
                        name: next,
                        drops: prevData.sections[index].drops,
                        data: prevData.sections[index].data
                    }
                    
                    prevData.sections[index] = newSection;

                    return prevData;
                });
                
                const newSectionName = {
                    ...prev,
                    [index]: next
                }
                return newSectionName;
            }
            return prev;
        });
    }

    function handleDropChange(event, indexOfSection) {
        setDrops(prevDrops => {
            let { value } = event.target;

            const dropRegex = /^[0-9]*$/;
            if (!dropRegex.test(value)) {
                return prevDrops;
            }
            
            else if (Number(value) > tempCourseData.sections[indexOfSection].data.length) {
                return prevDrops;
            }

            const newDrops = {
                ...prevDrops,
                [indexOfSection]: value
            };

            setTempCourseData(prevTempData => {
                const newSection = {
                    ...prevTempData.sections[indexOfSection],
                    drops: newDrops[indexOfSection]
                }
                
                const newSections = [...prevTempData.sections];
                newSections[indexOfSection] = newSection;

                const newTempData = {
                    ...prevTempData,
                    sections: newSections
                }

                return newTempData;
            });

            return newDrops;
        });
    }

    function handleEditHeader() {
        setEditHeader(true);
        setCourseName(courseData.name);
    }

    function handleEditSection(index) {
        setEditSection(prev => {
            const newEditSection = [...prev, index];
            return newEditSection;
        });

        setDrops(prev => {
            let prevSectionDrops = courseData.sections[index].drops;
            if (!prevSectionDrops) {
                prevSectionDrops = 0;
            } 
            const newDrops = {
                ...prev,
                [index]: prevSectionDrops
            }
            return newDrops;
        });
    }

    function handleNewAssessmentClick(index) {
        setAddNewAssessment(prev => {
            if (prev) {
                return [
                    ...prev,
                    index
                ];
            }
            else {
                return [index];
            }
        });
    }

    function handleNewSectionClick() {
        setAddNewSection(true);
    }

    function handleDeleteAssessment(indexOfSection, indexOfAssessment) {
        setTempCourseData(prev => {
            let newSectionData = prev.sections[indexOfSection].data;        // variable should be newassessmentdata but yk

            setWeightPossibleChange(prevWPC => {
                let newWPC = prevWPC;
                if (courseData.sections[indexOfSection].data[indexOfAssessment].weightAchieved && courseData.sections[indexOfSection].data[indexOfAssessment].weightPossible) {
                    newWPC -= courseData.sections[indexOfSection].data[indexOfAssessment].weightPossible;
                }
                return newWPC;
            });

            setWeightAchievedChange(prevWAC => {
                let newWAC = prevWAC;
                if (courseData.sections[indexOfSection].data[indexOfAssessment].weightAchieved) {
                    newWAC -= courseData.sections[indexOfSection].data[indexOfAssessment].weightAchieved;
                }
                return newWAC;
            });

            newSectionData[indexOfAssessment] = "deleted";
            let newSection = prev.sections;
            newSection[indexOfSection].data = newSectionData;
            const newState = {
                ...prev,
                sections: newSection
            }
            return newState;
        });
    }

    function handleDeleteSection(indexOfSection) {
        setTempCourseData(prev => {

            setWeightPossibleChange(prevWPC => {
                let newWPC = prevWPC;
                for (let i = 0; i < courseData.sections[indexOfSection].data.length; i++) {
                    if (courseData.sections[indexOfSection].data[i].weightAchieved && courseData.sections[indexOfSection].data[i].weightPossible) {
                        newWPC -= courseData.sections[indexOfSection].data[i].weightPossible;
                    }
                }
                return newWPC;
            });

            setWeightAchievedChange(prevWAC => {
                let newWAC = prevWAC;
                for (let i = 0; i < courseData.sections[indexOfSection].data.length; i++) {
                    if (courseData.sections[indexOfSection].data[i].weightAchieved) {
                        newWAC -= courseData.sections[indexOfSection].data[i].weightAchieved;
                    }
                }
                return newWAC;
            });

            let newSectionData = prev.sections;
            newSectionData[indexOfSection] = "deleted";
            const newState = {
                ...prev,
                sections: newSectionData
            }
            return newState;
        });
    }

    // SHARE SHTS ALL ARE HERE BRO --------------------------------------------------------------------------------------------------------------------------

    // code is null if not exists
    const [code, setCode] = useState(null);
    const [shareData, setShareData] = useState(null);
    const [showInfo, setShowInfo] = useState(false);

    useEffect(() => {
        const docRef = doc(db, "general", "shareCodes");
        getDoc(docRef)
            .then((docSnap) => {
                if (docSnap.exists()) {
                    setShareData(docSnap.data());
                }
                else {
                    alert("wtf your document doesn't exists")
                }
            }).catch((error) => {
                alert(error.message);
            }
        );
    }, []);     

    function handleShareClick() {
        let code = generateCode();
        const allCodes = Object.keys(shareData);
        while (allCodes.includes(code)) {
            code = generateCode();
        }

        // now the code is unique and doesnt exists yet
        setCode(code);
        setShareData(prev => {
            const newShareData = {
                ...prev,
                [code]: allCourseData[index]
            }

            updateShareDB(newShareData);

            return newShareData;
        });
    }

    async function updateShareDB(data) {
        const docRef = doc(db, "general", "shareCodes");
        await updateDoc(docRef, data);
    }

    function generateCode() {
        const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < 15; i++) {
            result += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return result;
    }

    function handleInfoClick() {
        setShowInfo(true);
        openInNewTab("/info");
    }

    // everytime u add a new edit, make sure to add the set to false here - very important
    function setAllEditsToFalse() {
        setEditHeader(false);
        setAddNewAssessment([]);
        setNewAssessmentItem({});
        setAddNewSection(false);
        setSectionName("");
        setEditSection([]);
        setEditSectionName({});
        setEditAssessment([]);
        setEditAssessmentItem({});
        setWeightAchievedChange(0);
        setWeightPossibleChange(0);
        setPreviouslyEdited(['', '']);
        setDrops({});
        setDeleteCourse(false);
        setTipsToDefault();
        setHoverToDefault();
    }

    function handleCancelEdits() {
        setAllEditsToFalse();
        handleAnimateExit();
        setTempCourseData(JSON.parse(JSON.stringify(courseData)));
    }

    const timer = useRef(new Set());
    useEffect(() => {
        const vals = Object.values(hover);
        const hovering = vals.findIndex(value => value === true);

        // hoveringName is the name of the thing that is being hovered over
        // ex. editAssessmentIcon, editMark, deleteSectionIcon, etc
        const hoveringName = hovering === -1 ? false : Object.keys(tips)[hovering];

        if (hoveringName) {
            const id = setTimeout(() => {
                setTips(prevTips => {
                    const newTips = {
                        ...prevTips,
                        [hoveringName]: true
                    }
                    return newTips
                });
            }, 1000);
            timer.current.add(id);
        }
        else {
            timer.current.forEach(timeoutId => clearTimeout(timeoutId));
            timer.current.clear();
            setTipsToDefault();
        }

        return () => {
            timer.current.forEach(timeoutId => clearTimeout(timeoutId));
            timer.current.clear();
        }
    }, [hover]);

    function startTooltipTimerMouse(e, type) {
        setHover(prevHover => {
            const newHover = {
                ...prevHover
            }
            newHover[type] = true;
            return newHover;
        });
        setTimeout(() => {
            const xPos = e.clientX;
            const yPos = e.clientY;
            setCoords([xPos, yPos]);
        }, 999);
    }

    function startTooltipTimerTouch(e, type) {
        setHover(prevHover => {
            const newHover = {
                ...prevHover
            }
            newHover[type] = true;
            return newHover;
        });
        setTimeout(() => {
            const xPos = e.touches[0].clientX;
            const yPos = e.touches[0].clientY;
            setTouchPos([xPos, yPos]);
        }, 999);
    }

    function setPosToDefault() {
        setTimeout(() => {
            setCoords([0, 0]);
            setTouchPos([0, 0]);
        }, 250);
    }

    function handleDontShowAgain() {
        setDontShowAgain(prev => !prev);
    }

    function handleDismissTip() {
        setShowTooltipsMessage(false);
        if (dontShowAgain) {
            updateShowTooltips();
        }
    }

    async function updateShowTooltips() {
        const docRef = doc(db, "users", userID);
        await updateDoc(docRef, {
            showTooltipMessage: false
        });
    }

    function handleSaveEdits() {

        // ensuring that the user has entered valid data into the new assessment data fields
        let hasWP = true;
        let nonZeroCredits = true;

        if (tempCourseData.credits == 0) {
            nonZeroCredits = false;
            handlePopup("Course credits cannot be 0", "/images/warning.png");
        }

        // if (Object.keys(newAssessmentItem).length > 0) {
        //     const vals = Object.values(newAssessmentItem);
        //     const length = vals.length;

        //     // ensuring the user entered a possible weight
        //     for (let i = 0; i < length; i++) {
        //         if (!(vals[i].weightPossible)) {
        //             hasWP = false;
        //             handlePopup("Must enter a possible weight", "/images/warning.png");
        //             break;
        //         }
            // }
        for (let i = 0; i < (tempCourseData.sections ? tempCourseData.sections.length : 0); i++) {
            if (tempCourseData.sections[i] !== 'deleted') {
                for (let j = 0; j < tempCourseData.sections[i].data.length; j++) {
                    if (!exists(tempCourseData.sections[i].data[j].weightPossible) && tempCourseData.sections[i].data[j] !== 'deleted') {
                        hasWP = false;
                        handlePopup("Must enter a possible weight", "/images/warning.png");
                        break;
                    }
                }
            }
        }

        if (tempCourseData.name.length === 0) {
            handlePopup("Course name cannot be empty", "/images/warning.png");
            return;
        }

        if (hasWP && nonZeroCredits) {
            handleAnimateExit();
            setAllEditsToFalse();
                
            // deleted assessments check
            const sections = JSON.parse(JSON.stringify(tempCourseData.sections));
            let indexOfSection = 0;
            let indexOfAssessment = 0;
            const sectionLength = sections ? sections.length : 0;
            while (indexOfSection < sectionLength) {
                if (sections[indexOfSection] === 'deleted') {
                    sections.splice(indexOfSection, 1);
                }
                else {
                    indexOfAssessment = 0;
                    while (indexOfAssessment < sections[indexOfSection] ? sections[indexOfSection].data.length : 0) {
                        if (sections[indexOfSection].data[indexOfAssessment] === 'deleted') {
                            sections[indexOfSection].data.splice(indexOfAssessment, 1);
                        }
                        else {
                            indexOfAssessment++;
                        }
                    }
                    indexOfSection++;
                }
            }

            for (let i = 0; i < sectionLength; i++) {
                // making sure drops is not undefined
                if (sections[i] && !sections[i].drops && sections[i].drops !== false) {
                    sections[i].drops = 0;
                }  
            }

            // setting the section drop values
            for (let i = 0; i < sectionLength; i++) {
                if (sections[i] === 'deleted') {
                    continue;
                }
                if (sections[i] && sections[i].data && sections[i].data[0]) {
                    const firstWP = sections[i].data[0].weightPossible;
                    let keepDropsOn = true;
                    for (let j = 0; j < sections[i].data.length; j++) {
                        if (sections[i].data[j] === 'deleted') {
                            continue;
                        }
                        if (sections[i].data[j] && (sections[i].data[j].weightPossible != firstWP)) {
                            keepDropsOn = false;
                            break;
                        }
                    }

                    if (keepDropsOn && sections[i] !== 'deleted') {
                        sections[i].drops = sections[i].drops === false ? 0 : sections[i].drops;
                    }
                    else if (sections[i] !== 'deleted') {
                        sections[i].drops = false;
                    }
                }
                else {
                    break;
                }
            }

            // computing slash marks, if any
            const slashRegex = /^[0-9]*[.]?[0-9]*[/][0-9]*[.]?[0-9]*$/;
            let slashValid = true;
            for (let i = 0; i < sectionLength; i++) {
                if (sections[i] === 'deleted') {
                    continue;
                }
                for (let j = 0; j < (sections[i] ? sections[i].data.length : 0); j++) {
                    if (sections[i].data[j] === 'deleted') {
                        continue;
                    }
                    if (slashRegex.test(sections[i].data[j].mark)) {
                        let firstNum = "";
                        let secondNum = "";
                        let foundSlash = false
                        for (let k = 0; k < sections[i].data[j].mark.length; k++) {
                            if (foundSlash && sections[i].data[j].mark.charAt(k) !== '/') {
                                secondNum += sections[i].data[j].mark.charAt(k);
                            }
                            else if (sections[i].data[j].mark.charAt(k) !== '/') {
                                firstNum += sections[i].data[j].mark.charAt(k);
                            }
                            if (sections[i].data[j].mark.charAt(k) === '/') {
                                foundSlash = true;
                            }
                        }
                        if (secondNum === '0' || secondNum === '') {
                            slashValid = false;
                            break;
                        }
                        else {
                            const newMark = Number((Number(firstNum) / Number(secondNum)) * 100);
                            sections[i].data[j].mark = newMark;
                        }
                    }
                }
            }

            // setting the drop values of each assessment within each section
            for (let i = 0; i < sectionLength; i++) {
                if (sections[i] && (sections[i].drops === false)) {
                    for (let j = 0; j < sections[i].data.length; j++) {
                        if (sections[i].data[j] !== 'deleted') {
                            sections[i].data[j].dropped = false;
                        }
                    }
                    continue;
                }
                if (sections[i]) {
                    const k = Number(sections[i].drops);
                    const kSmallestIndex = findKSmallestByMarkIndexes(sections[i].data, k);
                    for (let j = 0; j < sections[i].data.length; j++) {
                        if (kSmallestIndex.includes(j) && sections[i].data[j] !== 'deleted') {
                            sections[i].data[j].dropped = true;
                        }
                        else if (sections[i].data[j] !== 'deleted') {
                            sections[i].data[j].dropped = false;
                        }
                    }
                }
            }

            // updating the course's weightachieved and weightpossible
            let newWeightAchieved = 0;
            let newWeightPossible = 0;
            let totalWP = 0;
            for (let i = 0; i < sectionLength; i++) {
                for (let j = 0; j < (sections[i] ? sections[i].data.length : 0); j++) {
                    if (!(sections[i].data[j].dropped) && exists(sections[i].data[j].weightAchieved)) {
                        newWeightAchieved += Number(sections[i].data[j].weightAchieved);
                        newWeightPossible += Number(sections[i].data[j].weightPossible);
                    }
                    if (!sections[i].data[j].dropped) {
                        totalWP += Number(sections[i].data[j].weightPossible);
                    }
                }
            }

            if (Number(totalWP) > 100) {
                handlePopup("Total weight after drops may not exceed 100", "/images/warning.png");
                setTempCourseData(JSON.parse(JSON.stringify(courseData)));
                setDrops({});
            }
            else if (!slashValid) {
                handlePopup("Cannot divide by 0", "/images/warning.png");
                setTempCourseData(JSON.parse(JSON.stringify(courseData)));
            }
            else {
                tempCourseData.sections = sections;

    
                tempCourseData.weightPossible = Number(newWeightPossible);
                tempCourseData.weightAchieved = Number(newWeightAchieved);
    
                // setting the pages' course data
                setCourseData(() => {
                    
                    // setting the entire course data for DB purposes
                    setAllCourseData(prevData => {
                        let newData = prevData;
    
                        newData[index] = tempCourseData;

                        updateDB(newData);
        
                        return JSON.parse(JSON.stringify(newData));
                    });
                    return JSON.parse(JSON.stringify(tempCourseData));
                });
            }
        }
    }

    async function updateDB(data) {
        const courseDocRef = doc(db, "users", userID);
        await updateDoc(courseDocRef, {
            courses: data
        });
    }

    const avg = formatNumber(((courseData.weightAchieved / courseData.weightPossible)*100));
    let bufferRaw;
    let buffer;
    if (courseData.target && courseData.weightAchieved && courseData.weightPossible) {
        bufferRaw = (100 - Number(courseData.target)) - (Number(courseData.weightPossible) - Number(courseData.weightAchieved));
        buffer = formatNumber(bufferRaw);
        if (buffer < 0) {
            buffer = 0.00;
        }
    } 
    else {
        buffer = false;
    }

    const requirement = formatNumber(((Number(courseData.target) - Number(courseData.weightAchieved)) / (100 - Number(courseData.weightPossible)) * 100));

    // mapping all of the data to be rendered onto the page
    let sections;
    if (Array.isArray(courseData.sections)) {
        sections = courseData.sections.map((section, indexOfSection) => {
            const assessments = section.data.map((assessment, indexOfAssessment) => {
                if (tempCourseData.sections[indexOfSection] !== 'deleted' && tempCourseData.sections[indexOfSection].data[indexOfAssessment] !== 'deleted') {
                    const avg = formatNumber(((assessment.weightAchieved / assessment.weightPossible)*100));
                    // const wa = Number(assessment.weightAchieved).toFixed(2);
                    // const wp = Number(assessment.weightPossible).toFixed(2);
                    // const editMark = (editAssessmentItem[indexOfSection] && editAssessmentItem[indexOfSection][indexOfAssessment]) ? editAssessmentItem[indexOfSection][indexOfAssessment].mark : "";
    
                    const showEditButton = !(editAssessment.some(arr => arr.length === [indexOfSection, indexOfAssessment].length && arr.every((val, index) => val === [indexOfSection, indexOfAssessment][index])));
                    let dueDate = new Date(assessment.dueDate.seconds * 1000);
                    if (isValid(dueDate)) {
                        dueDate = dueDate.toDateString();
                        dueDate = dueDate.substring(4, dueDate.length);
                    }
                    else {
                        dueDate = false
                    }
                    // each assessment
                    return (
                        <div 
                            key={indexOfAssessment}
                            className="font-dosis tracking-widest text-xl font-normal uppercase"
                        >
                            <div className="flex flex-row border-b mdplus:border-b-[#ffffff50] mdplus:py-8 pt-8 border-b-[#ffffffaa] items-center justify-between">
                                {showEditButton ? (<div className="flex mdplus:flex-row flex-col w-full mdplus:items-center justify-between relative">
                                    <div className="flex mdplus:flex-row justify-start flex-col mdplus:items-center mdplus:w-2/3">
                                        <div className="mdplus:mb-0 mb-4 mdplus:hidden flex flex-row w-full justify-between items-center">
                                            <div className="relative sm:text-xl text-lg max-w-[300px] sm:max-w-none overflow-hidden truncate">
                                                {assessment.name}
                                            {assessment.dropped && (<p className="absolute right-[-90px] top-[3px] text-[#ffffff50] text-base block mdplus:hidden">DROPPED</p>)}
                                            </div>
                                            <motion.button
                                                className="bg-[url('/public/images/edit.png')] bg-contain bg-center bg-no-repeat w-[40px] h-[40px] block mdplus:hidden"
                                                id="editAssessmentIcon"
                                                initial={{ scale: 1, opacity: 0.5 }}
                                                whileHover={{
                                                    scale: 1.1,
                                                    opacity: 0.9,
                                                    transition: {
                                                        duration: 0.25
                                                    }
                                                }}
                                                whileTap={{
                                                    scale: 0.9,
                                                    transition: {
                                                        duration: 0.125
                                                    }
                                                }}
                                                onClick={() => {
                                                    handleEditAssessment(indexOfSection, indexOfAssessment)
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseEnter={(e) => {
                                                    startTooltipTimerMouse(e, "editAssessmentIcon");
                                                }}
                                                onTouchStart={(e) => {
                                                    startTooltipTimerTouch(e, "editAssessmentIcon");
                                                }}
                                                onTouchEnd={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                            />
                                            <AnimatePresence>
                                            {tips.editAssessmentIcon &&
                                                <motion.div
                                                    key="tooltip"
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ duration: 0.25 }}
                                                    exit={{ opacity: 0 }}
                                                    className="absolute"
                                                >
                                                    <Tip text="Click to edit" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                                </motion.div>}
                                            </AnimatePresence>
                                        </div>
                                        <p className="hidden mdplus:block">{assessment.name}</p>
                                        <p className="text-[#ffffff50] text-base ml-4 mdplus:block hidden">{dueDate === false ? "NO DUE DATE" : `DUE DATE: ${dueDate}`}</p>
                                        <div className="text-[#ffffff50] text-base mdplus:hidden border-b border-b-[#ffffff30] w-full flex flex-row justify-between">
                                            <p>DUE DATE</p>
                                            <p>{dueDate === false ? "NO DUE DATE" : dueDate}</p>
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <p className="mdplus:block hidden">{avg}%</p>
                                        <div className="mdplus:hidden w-full flex flex-row border-b border-b-[#ffffff30] justify-between pt-2">
                                            <p className="text-[#ffffff50] text-base relative top-[5px] pb-0">MARK</p>
                                            <p>{avg}%</p>
                                        </div>
                                        {assessment.dropped && (<p className="absolute left-[-100px] top-[3px] text-[#ffffff50] text-base hidden mdplus:block">DROPPED</p>)}
                                    </div>
                                        <p className="mdplus:block hidden">{exists(assessment.weightAchieved) ? formatNumber(Number(assessment.weightAchieved)) : ""}/{formatNumber(Number(assessment.weightPossible))}</p>
                                        <div className="mdplus:hidden w-full flex flex-row border-b border-b-[#ffffff30] justify-between pt-2">
                                            <p className="text-[#ffffff50] text-base relative top-[5px] pb-0">WEIGHT</p>
                                            <p>{exists(assessment.weightAchieved) ? formatNumber(Number(assessment.weightAchieved)) : ""}/{formatNumber(Number(assessment.weightPossible))}</p>
                                        </div>
                                    <motion.button
                                        className="bg-[url('/public/images/edit.png')] bg-contain bg-center bg-no-repeat h-[40px] w-[40px] mdplus:block hidden"
                                        initial={{ scale: 1, opacity: 0.5 }}
                                        whileHover={{
                                            scale: 1.1,
                                            opacity: 0.9,
                                            transition: {
                                                duration: 0.25
                                            }
                                        }}
                                        whileTap={{
                                            scale: 0.9,
                                            transition: {
                                                duration: 0.125
                                            }
                                        }}
                                        onClick={() => {
                                            handleEditAssessment(indexOfSection, indexOfAssessment)
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseEnter={(e) => {
                                            startTooltipTimerMouse(e, "editAssessmentIcon");
                                        }}
                                        onTouchStart={(e) => {
                                            startTooltipTimerTouch(e, "editAssessmentIcon");
                                        }}
                                        onTouchEnd={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseLeave={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                    />
                                    <AnimatePresence>
                                    {tips.editAssessmentIcon &&
                                        <motion.div
                                            key="tooltip"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.25 }}
                                            exit={{ opacity: 0 }}
                                            className="absolute"
                                        >
                                            <Tip text="Click to edit" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                        </motion.div>}
                                    </AnimatePresence>
                                </div>) : (
                                <div className="flex mdplus:flex-row flex-col justify-between w-full mdplus:items-center">
                                    <div className="mdplus:w-2/3 flex mdplus:flex-row flex-col justify-start mdplus:items-center">
                                        <div className="flex flex-row justify-between items-center">
                                            <input 
                                                type="text"
                                                placeholder="NAME"
                                                name="name"
                                                autoComplete="off"
                                                onChange={(event) => handleEditAssessmentChange(event, indexOfSection, indexOfAssessment)}
                                                value={(editAssessmentItem[indexOfSection] && editAssessmentItem[indexOfSection][indexOfAssessment] && exists(editAssessmentItem[indexOfSection][indexOfAssessment])) ? editAssessmentItem[indexOfSection][indexOfAssessment].name : ""}
                                                className="uppercase mr-4 mdplus:mb-0 mb-2 font-dosis tracking-widest text-lg font-normal bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[40%] mdplus:w-auto"
                                                onClick={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseEnter={(e) => {
                                                    startTooltipTimerMouse(e, "editAssessmentName");
                                                }}
                                                onTouchStart={(e) => {
                                                    startTooltipTimerTouch(e, "editAssessmentName");
                                                }}
                                                onTouchEnd={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                            />
                                            <AnimatePresence>
                                            {tips.editAssessmentName &&
                                                <motion.div
                                                    key="tooltip"
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ duration: 0.25 }}
                                                    exit={{ opacity: 0 }}
                                                    className="absolute"
                                                >
                                                    <Tip text="Click to edit name" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="right" />
                                                </motion.div>}
                                            </AnimatePresence>
                                            <motion.button
                                                className="bg-[url('/public/images/trashIcon.png')] bg-contain bg-center bg-no-repeat h-[40px] w-[40px] block mdplus:hidden relative top-[-6px]"
                                                initial={{ scale: 1, opacity: 0.5 }}
                                                whileHover={{
                                                    scale: 1.1,
                                                    opacity: 0.9,
                                                    transition: {
                                                        duration: 0.25
                                                    }
                                                }}
                                                whileTap={{
                                                    scale: 0.9,
                                                    transition: {
                                                        duration: 0.125
                                                    }
                                                }}
                                                onClick={() => {
                                                    handleDeleteAssessment(indexOfSection, indexOfAssessment)
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseEnter={(e) => {
                                                    startTooltipTimerMouse(e, "deleteCourseIcon");
                                                }}
                                                onTouchStart={(e) => {
                                                    startTooltipTimerTouch(e, "deleteCourseIcon");
                                                }}
                                                onTouchEnd={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                            />
                                            <AnimatePresence>
                                            {tips.deleteCourseIcon &&
                                                <motion.div
                                                    key="tooltip"
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ duration: 0.25 }}
                                                    exit={{ opacity: 0 }}
                                                    className="absolute"
                                                >
                                                    <Tip text="Click to delete assessment" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                                </motion.div>}
                                            </AnimatePresence>
                                        </div>
                                        <div className="border-b border-b-[#ffffff30] mdplus:border-0 w-full mdplus:w-auto flex flex-row pb-1 mdplus:pb-0 items-center justify-between mdplus:justify-normal mdplus:block">
                                            <p className="text-[#ffffff50] relative top-[7px] text-base block mdplus:hidden">DUE DATE</p>
                                            <input 
                                                type="date"
                                                max="2099-12-31"
                                                name="dueDate"
                                                autoComplete="off"
                                                onChange={(event) => handleEditAssessmentChange(event, indexOfSection, indexOfAssessment)}
                                                // value={(editAssessmentItem[indexOfSection] && editAssessmentItem[indexOfSection][indexOfAssessment] && exists(editAssessmentItem[indexOfSection][indexOfAssessment])) ? editAssessmentItem[indexOfSection][indexOfAssessment].dueDate : ""}
                                                className="uppercase relative bottom-[1px] font-dosis tracking-widest text-lg font-normal bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[40%] mdplus:w-auto"
                                                onClick={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseEnter={(e) => {
                                                    startTooltipTimerMouse(e, "editDueDate");
                                                }}
                                                onTouchStart={(e) => {
                                                    startTooltipTimerTouch(e, "editDueDate");
                                                }}
                                                onTouchEnd={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                            />
                                            <AnimatePresence>
                                            {tips.editDueDate &&
                                                <motion.div
                                                    key="tooltip"
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ duration: 0.25 }}
                                                    exit={{ opacity: 0 }}
                                                    className="absolute"
                                                >
                                                    <Tip text="Click to edit due date. Leave blank for no due date." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                                </motion.div>}
                                            </AnimatePresence>
                                        </div>
                                    </div>
                                    <div className="flex mdplus:flex-row flex-col mdplus:items-center justify-between mdplus:w-1/3 w-full">
                                        <div className="border-b border-b-[#ffffff30] mdplus:border-0 w-full mdplus:w-auto flex flex-row pb-1 mdplus:pb-0 items-center justify-between mdplus:justify-normal mdplus:block">
                                            <p className="text-[#ffffff50] relative top-[7px] text-base block mdplus:hidden">MARK</p>
                                            <input 
                                                type="text"
                                                placeholder="MARK"
                                                name="mark"
                                                autoComplete="off"
                                                onChange={(event) => handleEditAssessmentChange(event, indexOfSection, indexOfAssessment)}
                                                value={(editAssessmentItem[indexOfSection] && editAssessmentItem[indexOfSection][indexOfAssessment] && exists(editAssessmentItem[indexOfSection][indexOfAssessment])) ? editAssessmentItem[indexOfSection][indexOfAssessment].mark : ""}
                                                className="uppercase relative bottom-[1px] font-dosis tracking-widest text-lg font-normal bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[40%] mdplus:w-[70%] lg:w-[80%] xl:w-[100%]"
                                                onClick={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseEnter={(e) => {
                                                    startTooltipTimerMouse(e, "editMark");
                                                }}
                                                onTouchStart={(e) => {
                                                    startTooltipTimerTouch(e, "editMark");
                                                }}
                                                onTouchEnd={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                            />
                                            <AnimatePresence>
                                            {tips.editMark &&
                                                <motion.div
                                                    key="tooltip"
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ duration: 0.25 }}
                                                    exit={{ opacity: 0 }}
                                                    className="absolute"
                                                >
                                                    <Tip text="Click to edit mark. You can type a number between 0 - 100 as a percentage, or a fractional value like 17.5/19." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                                </motion.div>}
                                            </AnimatePresence>
                                        </div>
                                        <div className="border-b border-b-[#ffffff30] mdplus:border-0 w-full mdplus:w-auto flex flex-row pb-1 mdplus:pb-0 items-center justify-between mdplus:justify-normal mdplus:block">
                                            <p className="text-[#ffffff50] relative top-[7px] text-base block mdplus:hidden">WEIGHT</p>
                                            <div className="flex flex-row mdplus:w-auto w-[40%] mdplus:justify-normal justify-between">
                                                <input 
                                                    type="text"
                                                    name="weightAchieved"
                                                    autoComplete="off"
                                                    onChange={(event) => handleEditAssessmentChange(event, indexOfSection, indexOfAssessment)}
                                                    value={(editAssessmentItem[indexOfSection] && editAssessmentItem[indexOfSection][indexOfAssessment] && exists(editAssessmentItem[indexOfSection][indexOfAssessment])) ? editAssessmentItem[indexOfSection][indexOfAssessment].weightAchieved : ""}
                                                    className="uppercase relative bottom-[1px] font-dosis tracking-widest text-lg font-normal mdplus:w-[30px] bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[45%]"
                                                    onClick={() => {
                                                        setHoverToDefault();
                                                        setPosToDefault();
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        startTooltipTimerMouse(e, "editWeightAchieved");
                                                    }}
                                                    onTouchStart={(e) => {
                                                        startTooltipTimerTouch(e, "editWeightAchieved");
                                                    }}
                                                    onTouchEnd={() => {
                                                        setHoverToDefault();
                                                        setPosToDefault();
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHoverToDefault();
                                                        setPosToDefault();
                                                    }}
                                                />
                                                <AnimatePresence>
                                                {tips.editWeightAchieved &&
                                                    <motion.div
                                                        key="tooltip"
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        transition={{ duration: 0.25 }}
                                                        exit={{ opacity: 0 }}
                                                        className="absolute"
                                                    >
                                                        <Tip text="Click to edit weight achieved. This is the percentage of the course that you have completed with this assessment." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                                    </motion.div>}
                                                </AnimatePresence>
                                                <p className="font-dosis text-lg pl-2 pr-1 font-normal">/</p>
                                                <input 
                                                    type="text"
                                                    name="weightPossible"
                                                    autoComplete="off"
                                                    onChange={(event) => handleEditAssessmentChange(event, indexOfSection, indexOfAssessment)}
                                                    value={(editAssessmentItem[indexOfSection] && editAssessmentItem[indexOfSection][indexOfAssessment] && exists(editAssessmentItem[indexOfSection][indexOfAssessment])) ? editAssessmentItem[indexOfSection][indexOfAssessment].weightPossible : ""}
                                                    className="uppercase relative bottom-[1px] font-dosis tracking-widest text-lg font-normal mdplus:w-[30px] bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[45%]"
                                                    onClick={() => {
                                                        setHoverToDefault();
                                                        setPosToDefault();
                                                    }}
                                                    onMouseEnter={(e) => {
                                                        startTooltipTimerMouse(e, "editWeightPossible");
                                                    }}
                                                    onTouchStart={(e) => {
                                                        startTooltipTimerTouch(e, "editWeightPossible");
                                                    }}
                                                    onTouchEnd={() => {
                                                        setHoverToDefault();
                                                        setPosToDefault();
                                                    }}
                                                    onMouseLeave={() => {
                                                        setHoverToDefault();
                                                        setPosToDefault();
                                                    }}
                                                />
                                                <AnimatePresence>
                                                {tips.editWeightPossible &&
                                                    <motion.div
                                                        key="tooltip"
                                                        initial={{ opacity: 0 }}
                                                        animate={{ opacity: 1 }}
                                                        transition={{ duration: 0.25 }}
                                                        exit={{ opacity: 0 }}
                                                        className="absolute"
                                                    >
                                                        <Tip text="Click to edit total possible weight of this assessment. This is the percentage of the course that this assessment is worth." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                                    </motion.div>}
                                                </AnimatePresence>
                                            </div>
                                        </div>
                                        <motion.button
                                            className="w-[40px] h-[40px] bg-[url('/public/images/trashIcon.png')] bg-contain bg-center bg-no-repeat hidden mdplus:block"
                                            initial={{ scale: 1, opacity: 0.5 }}
                                            whileHover={{
                                                scale: 1.1,
                                                opacity: 0.9,
                                                transition: {
                                                    duration: 0.25
                                                }
                                            }}
                                            whileTap={{
                                                scale: 0.9,
                                                transition: {
                                                    duration: 0.125
                                                }
                                            }}
                                            onClick={() => {
                                                handleDeleteAssessment(indexOfSection, indexOfAssessment)
                                                setHoverToDefault();
                                                setPosToDefault();
                                            }}
                                            onMouseEnter={(e) => {
                                                startTooltipTimerMouse(e, "deleteAssessmentIcon");
                                            }}
                                            onTouchStart={(e) => {
                                                startTooltipTimerTouch(e, "deleteAssessmentIcon");
                                            }}
                                            onTouchEnd={() => {
                                                setHoverToDefault();
                                                setPosToDefault();
                                            }}
                                            onMouseLeave={() => {
                                                setHoverToDefault();
                                                setPosToDefault();
                                            }}
                                        />
                                        <AnimatePresence>
                                    {tips.deleteAssessmentIcon &&
                                        <motion.div
                                            key="tooltip"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.25 }}
                                            exit={{ opacity: 0 }}
                                            className="absolute"
                                        >
                                            <Tip text="Click to delete assessment" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                        </motion.div>}
                                    </AnimatePresence>
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    )
                }
            });
    
            if (tempCourseData.sections[indexOfSection] !== 'deleted') {

                // each section
                return (
                    <div 
                        key={indexOfSection}
                        className="font-bold text-4xl"
                    >
                        <div className="border-b pb-2 mt-16 flex flex-row justify-between items-center">
                            {!editSection.includes(indexOfSection) ? ((section.drops === false) ? (
                            <div className="mdplus:w-2/3 w-[85%]">
                                <p className="overflow-hidden xs:text-3xl text-[1.5rem] sm:text-4xl sm:font-bold font-semibold">{section.name}</p>
                            </div>
                            ) : (
                                <div className="mdplus:w-2/3 w-full flex xs:flex-row flex-col justify-start gap-2 xs:gap-6 xs:items-end">
                                    <p className="truncate xl:max-w-[580px] lg:max-w-[450px] md:max-w-[350px] sm:max-w-[300px] max-w-[200px] overflow-hidden text-2xl sm:text-4xl font-semibold">
                                        {section.name}
                                    </p>
                                    <p className="sm:text-lg text-base font-dosis tracking-widest text-[#ffffff50] font-normal pb-[1px]">DROPS: {Number(section.drops)}</p>
                                </div>
                            )) : ((section.drops === false) ? (
                            <>
                                <input 
                                    autoComplete="off"
                                    name="name"
                                    placeholder="Section Name"
                                    type="text"
                                    className="bg-[#121220] xs:w-2/3 w-full focus:outline-none sm:text-4xl text-3xl ms:font-bold font-montserrat font-bold opacity-70 hover:opacity-100 focus:opacity-100"
                                    value={editSectionName[indexOfSection] ? editSectionName[indexOfSection] : ""}
                                    onChange={(event) => handleEditSectionNameChange(event, indexOfSection)}
                                    onClick={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseEnter={(e) => {
                                        startTooltipTimerMouse(e, "editSectionName");
                                    }}
                                    onTouchStart={(e) => {
                                        startTooltipTimerTouch(e, "editSectionName");
                                    }}
                                    onTouchEnd={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseLeave={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                />
                                <AnimatePresence>
                                {tips.editSectionName &&
                                    <motion.div
                                        key="tooltip"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.25 }}
                                        exit={{ opacity: 0 }}
                                        className="absolute"
                                    >
                                        <Tip text="Click to edit name" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="right" />
                                    </motion.div>}
                                </AnimatePresence>
                            </>
                            ) : (
                                <div className="mdplus:w-2/3 w-full flex xs:flex-row flex-col xs:items-end gap-2 xs:gap-6 justify-start">
                                    <input 
                                        autoComplete="off"
                                        name="name"
                                        placeholder="Section Name"
                                        type="text"
                                        className="bg-[#121220] focus:outline-none font-montserrat sm:text-4xl text-[1.5rem] pb-0 ms:font-bold font-semibold text-4xl opacity-70 hover:opacity-100 focus:opacity-100 border-b border-b-[#ffffff50] md:max-w-[350px] sm:max-w-[300px] max-w-[200px]"
                                        value={editSectionName[indexOfSection] ? editSectionName[indexOfSection] : ""}
                                        onChange={(event) => handleEditSectionNameChange(event, indexOfSection)}
                                        onClick={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseEnter={(e) => {
                                            startTooltipTimerMouse(e, "editSectionName");
                                        }}
                                        onTouchStart={(e) => {
                                            startTooltipTimerTouch(e, "editSectionName");
                                        }}
                                        onTouchEnd={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseLeave={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                    />
                                    <input
                                        autoComplete="off"
                                        name="drops"
                                        placeholder="Drops"
                                        type="text"
                                        className="bg-[#121220] focus:outline-none font-dosis tracking-widest font-normal sm:text-lg text-base opacity-70 hover:opacity-100 focus:opacity-100 border-b border-b-[#ffffff50] sm:w-[75px] w-[50px]"
                                        value={exists(drops[indexOfSection]) ? drops[indexOfSection] : ""}
                                        onChange={(event) => handleDropChange(event, indexOfSection)}
                                        onClick={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseEnter={(e) => {
                                            startTooltipTimerMouse(e, "editDrops");
                                        }}
                                        onTouchStart={(e) => {
                                            startTooltipTimerTouch(e, "editDrops");
                                        }}
                                        onTouchEnd={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseLeave={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                    />
                                    <AnimatePresence>
                                    {tips.editDrops &&
                                        <motion.div
                                            key="tooltip"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.25 }}
                                            exit={{ opacity: 0 }}
                                            className="absolute"
                                        >
                                            <Tip text="Click to edit number of dropped assessments in this section. Drops only valid if every assessment in the section has the same weight." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="right" />
                                        </motion.div>}
                                    </AnimatePresence>
                                </div>
                            ))}
                            <p className="text-sm font-normal text-[#ffffff50] pt-4 mdplus:block hidden">MARK</p>
                            <p className="text-sm font-normal text-[#ffffff50] pt-4 mdplus:block hidden">WEIGHT</p>
                            {!editSection.includes(indexOfSection) ? (<><motion.button 
                                className="w-[40px] h-[40px] bg-[url('/public/images/edit.png')] bg-contain bg-center bg-no-repeat"
                                initial={{ scale: 1, opacity: 0.5 }}
                                whileHover={{
                                    scale: 1.1,
                                    opacity: 0.9,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.9,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => {
                                    handleEditSection(indexOfSection);
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseEnter={(e) => {
                                    startTooltipTimerMouse(e, "editSectionIcon");
                                }}
                                onTouchStart={(e) => {
                                    startTooltipTimerTouch(e, "editSectionIcon");
                                }}
                                onTouchEnd={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseLeave={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                            />
                            <AnimatePresence>
                            {tips.editSectionIcon &&
                                <motion.div
                                    key="tooltip"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.25 }}
                                    exit={{ opacity: 0 }}
                                    className="absolute"
                                >
                                    <Tip text="Click to edit" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                </motion.div>}
                            </AnimatePresence>
                            </>) : (<>
                                <motion.button 
                                    className="w-[40px] h-[40px] bg-[url('/public/images/trashIcon.png')] bg-contain bg-center bg-no-repeat"
                                    initial={{ scale: 1, opacity: 0.5 }}
                                    whileHover={{
                                        scale: 1.1,
                                        opacity: 0.9,
                                        transition: {
                                            duration: 0.25
                                        }
                                    }}
                                    whileTap={{
                                        scale: 0.9,
                                        transition: {
                                            duration: 0.125
                                        }
                                    }}
                                    onClick={() => {
                                        handleDeleteSection(indexOfSection);
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseEnter={(e) => {
                                        startTooltipTimerMouse(e, "deleteSectionIcon");
                                    }}
                                    onTouchStart={(e) => {
                                        startTooltipTimerTouch(e, "deleteSectionIcon");
                                    }}
                                    onTouchEnd={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseLeave={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                />
                                <AnimatePresence>
                                {tips.deleteSectionIcon &&
                                    <motion.div
                                        key="tooltip"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.25 }}
                                        exit={{ opacity: 0 }}
                                        className="absolute"
                                    >
                                        <Tip text="Click to delete section" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                    </motion.div>}
                                </AnimatePresence>
                                </>
                            )}
                        </div>
                        {assessments}
                        <div className="flex flex-row py-6 items-center justify-around">
    
                            {(addNewAssessment.length !== 0 && addNewAssessment.includes(indexOfSection)) ? (
                            <div className="flex mdplus:flex-row flex-col justify-between w-full mdplus:items-end">
                                <div className="mdplus:w-2/3 w-full flex mdplus:flex-row flex-col justify-start mdplus:items-end">
                                    <input 
                                        type="text"
                                        placeholder="NAME"
                                        name="name"
                                        autoComplete="off"
                                        onChange={(event) => handleNewAssessmentChange(event, indexOfSection, section.data.length)}
                                        value={newAssessmentItem[indexOfSection] && exists(newAssessmentItem[indexOfSection].name) ? newAssessmentItem[indexOfSection].name : ""}
                                        className="uppercase m-0 mdplus:mr-4 font-dosis tracking-widest text-lg font-normal bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[40%] mdplus:w-auto"
                                        onClick={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseEnter={(e) => {
                                            startTooltipTimerMouse(e, "createAssessmentName");
                                        }}
                                        onTouchStart={(e) => {
                                            startTooltipTimerTouch(e, "createAssessmentName");
                                        }}
                                        onTouchEnd={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseLeave={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                    />
                                    <AnimatePresence>
                                    {tips.createAssessmentName &&
                                        <motion.div
                                            key="tooltip"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.25 }}
                                            exit={{ opacity: 0 }}
                                            className="absolute"
                                        >
                                            <Tip text="Click to create name" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="right" />
                                        </motion.div>}
                                    </AnimatePresence>
                                    <div className="border-b border-b-[#ffffff30] mdplus:border-0 w-full mdplus:w-auto flex flex-row pb-1 mdplus:pb-0 items-center justify-between mdplus:justify-normal mdplus:block">
                                        <p className="text-[#ffffff50] relative top-[7px] text-base block mdplus:hidden font-dosis font-normal tracking-widest">DUE DATE</p>
                                        <input 
                                            type="date"
                                            max="2199-12-31"
                                            name="dueDate"
                                            autoComplete="off"
                                            onChange={(event) => handleNewAssessmentChange(event, indexOfSection, section.data.length)}
                                            // value={newAssessmentItem[indexOfSection] && exists(newAssessmentItem[indexOfSection].dueDate) ? newAssessmentItem[indexOfSection].dueDate : ""}
                                            className="uppercase relative bottom-[1px] font-dosis tracking-widest text-lg font-normal bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[40%] mdplus:w-auto"
                                            onClick={() => {
                                                setHoverToDefault();
                                                setPosToDefault();
                                            }}
                                            onMouseEnter={(e) => {
                                                startTooltipTimerMouse(e, "createAssessmentDueDate");
                                            }}
                                            onTouchStart={(e) => {
                                                startTooltipTimerTouch(e, "createAssessmentDueDate");
                                            }}
                                            onTouchEnd={() => {
                                                setHoverToDefault();
                                                setPosToDefault();
                                            }}
                                            onMouseLeave={() => {
                                                setHoverToDefault();
                                                setPosToDefault();
                                            }}
                                        />
                                        <AnimatePresence>
                                        {tips.createAssessmentDueDate &&
                                            <motion.div
                                                key="tooltip"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                transition={{ duration: 0.25 }}
                                                exit={{ opacity: 0 }}
                                                className="absolute"
                                            >
                                                <Tip text="Click to create due date. Leave blank for no due date." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                            </motion.div>}
                                        </AnimatePresence>
                                    </div>
                                </div>
                                <div className="flex mdplus:flex-row flex-col mdplus:items-end justify-between mdplus:w-1/3 w-full">
                                    <div className="border-b border-b-[#ffffff30] mdplus:border-0 w-full mdplus:w-auto flex flex-row pb-1 mdplus:pb-0 items-center justify-between mdplus:justify-normal mdplus:block">
                                        <p className="text-[#ffffff50] relative top-[7px] text-base block mdplus:hidden font-dosis font-normal tracking-widest">MARK</p>
                                        <input 
                                            type="text"
                                            placeholder="MARK"
                                            name="mark"
                                            autoComplete="off"
                                            onChange={(event) => handleNewAssessmentChange(event, indexOfSection, section.data.length)}
                                            value={newAssessmentItem[indexOfSection] && exists(newAssessmentItem[indexOfSection].mark) ? newAssessmentItem[indexOfSection].mark : ""}
                                            className="mdplus:mr-4 font-dosis tracking-widest text-lg font-normal bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[40%] mdplus:w-[67%] lg:w-[74%] xl:w-[93%]"
                                            onClick={() => {
                                                setHoverToDefault();
                                                setPosToDefault();
                                            }}
                                            onMouseEnter={(e) => {
                                                startTooltipTimerMouse(e, "createAssessmentMark");
                                            }}
                                            onTouchStart={(e) => {
                                                startTooltipTimerTouch(e, "createAssessmentMark");
                                            }}
                                            onTouchEnd={() => {
                                                setHoverToDefault();
                                                setPosToDefault();
                                            }}
                                            onMouseLeave={() => {
                                                setHoverToDefault();
                                                setPosToDefault();
                                            }}
                                        />
                                        <AnimatePresence>
                                        {tips.createAssessmentMark &&
                                            <motion.div
                                                key="tooltip"
                                                initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                transition={{ duration: 0.25 }}
                                                exit={{ opacity: 0 }}
                                                className="absolute"
                                            >
                                                <Tip text="Click to create mark. You can type a number between 0 - 100 as a percentage, or a fractional value like 17.5/19." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                            </motion.div>}
                                        </AnimatePresence>
                                    </div>
                                    <div className="border-b border-b-[#ffffff30] mdplus:border-0 w-full mdplus:w-auto flex flex-row pb-1 mdplus:pb-0 items-center justify-between mdplus:justify-normal mdplus:block">
                                        <p className="text-[#ffffff50] relative top-[7px] text-base block mdplus:hidden font-dosis font-normal tracking-widest">WEIGHT</p>
                                        <div className="flex flex-row mdplus:w-auto w-[40%] mdplus:justify-normal justify-between">
                                            <input 
                                                type="text"
                                                name="weightAchieved"
                                                autoComplete="off"
                                                onChange={(event) => handleNewAssessmentChange(event, indexOfSection, section.data.length)}
                                                value={newAssessmentItem[indexOfSection] && exists(newAssessmentItem[indexOfSection].weightAchieved) ? newAssessmentItem[indexOfSection].weightAchieved : ""}
                                                className="font-dosis tracking-widest text-lg font-normal mdplus:w-[30px] bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[45%]"
                                                onClick={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseEnter={(e) => {
                                                    startTooltipTimerMouse(e, "createAssessmentWeightAchieved");
                                                }}
                                                onTouchStart={(e) => {
                                                    startTooltipTimerTouch(e, "createAssessmentWeightAchieved");
                                                }}
                                                onTouchEnd={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                            />
                                            <AnimatePresence>
                                            {tips.createAssessmentWeightAchieved &&
                                                <motion.div
                                                    key="tooltip"
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ duration: 0.25 }}
                                                    exit={{ opacity: 0 }}
                                                    className="absolute"
                                                >
                                                    <Tip text="Click to create weight achieved. This is the percentage of the course that you have completed with this assessment." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                                </motion.div>}
                                            </AnimatePresence>
                                            <p className="font-dosis text-lg pl-2 pr-1 font-normal">/</p>
                                            <input 
                                                type="text"
                                                name="weightPossible"
                                                autoComplete="off"
                                                onChange={(event) => handleNewAssessmentChange(event, indexOfSection, section.data.length)}
                                                value={newAssessmentItem[indexOfSection] && exists(newAssessmentItem[indexOfSection].weightPossible) ? newAssessmentItem[indexOfSection].weightPossible : ""}
                                                className="font-dosis tracking-widest text-lg font-normal mdplus:w-[30px] bg-[#12122000] border-b focus:outline-none opacity-70 hover:opacity-100 focus:opacity-100 w-[45%]"
                                                onClick={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseEnter={(e) => {
                                                    startTooltipTimerMouse(e, "createAssessmentWeightPossible");
                                                }}
                                                onTouchStart={(e) => {
                                                    startTooltipTimerTouch(e, "createAssessmentWeightPossible");
                                                }}
                                                onTouchEnd={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                                onMouseLeave={() => {
                                                    setHoverToDefault();
                                                    setPosToDefault();
                                                }}
                                            />
                                            <AnimatePresence>
                                            {tips.createAssessmentWeightPossible &&
                                                <motion.div
                                                    key="tooltip"
                                                    initial={{ opacity: 0 }}
                                                    animate={{ opacity: 1 }}
                                                    transition={{ duration: 0.25 }}
                                                    exit={{ opacity: 0 }}
                                                    className="absolute"
                                                >
                                                    <Tip text="Click to create total possible weight of this assessment. This is the percentage of the course that this assessment is worth." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                                </motion.div>}
                                            </AnimatePresence>
                                        </div>
                                    </div>
                                    <div className="w-[40px]"></div>
                                </div>
                            </div>
                            ) : (
                            <motion.div 
                                className="flex flex-row items-center hover:cursor-pointer relative right-[4px]"
                                initial={{
                                    scale: 1,
                                    opacity: 0.3
                                }}
                                whileHover={{
                                    scale: 1.05,
                                    opacity: 0.8,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.95,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => handleNewAssessmentClick(indexOfSection)}
                            >
                                <img 
                                    src="/images/addButton.png"
                                    alt=""
                                    className="xs:w-[50px] w-[40px]"
                                />
                                <div className="xs:text-xl text-lg font-dosis tracking-widest font-normal">ADD NEW ASSESSMENT</div>
                            </motion.div>)}
    
                        </div>
                    </div>
                )
            }
        });
    }

    // async function justatest() {
    //     const docRef = doc(db, "users", userID);
    //     await updateDoc(docRef, {
    //         courses: exampledata
    //     });
    // }

    // justatest();



    //  FRAMER MOTION SHTS ARE HERE
    async function handleAnimateEnter() {
        await animate(scope.current, { opacity: 1 }, { duration: 0.25 });
    }

    async function handleAnimateExit() {
        await animate(scope.current, { opacity: 0.3 }, { duration: 0.25 });
    }

    const headerClassName = exists(code) ? "flex xl:flex-row flex-col pb-0 mdplus:pb-2 xl:pb-0 gap-1 xl:gap-2 xl:items-center" : "flex flex-row pb-0 mdplus:pb-2 xl:pb-0 gap-2 items-center";

    return (
        <div className="font-montserrat relative">

            {deleteCourse && <div 
                className="fixed top-0 left-0 bg-[#000000aa] text-6xl text-white h-screen w-screen z-40"
                // onClick={() => alert("Clicked outside")}
            >
                <div className="w-full h-full flex flex-col justify-around">
                    <div className="bg-[#121220] xl:w-[35%] lg:w-[40%] sm:w-1/2 xs:w-[75%] w-[80%] xs:h-[300px] h-[230px] rounded-2xl border-4 mx-auto flex flex-col justify-between">
                        <p className="lg:mt-8 xs:mt-10 mt-6 w-fit mx-auto 2xl:text-5xl lg:text-[2.1rem] mdplus:text-3xl md:text-[1.7rem] sm:text-3xl xs:text-[1.6rem] text-xl">Delete <span className="font-bold">{courseData.name}</span>?</p>
                        <div className="flex flex-col xs:gap-8 gap-4 justify-between m-8">
                            <motion.button 
                                className="border-4 lg:text-3xl xs:text-2xl text-xl font-bold p-2"
                                initial={{ scale: 1.0 }}
                                whileHover={{
                                    scale: 1.05,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.95,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => {
                                    setDeleteCourse(false);
                                }}
                            >
                                CANCEL
                            </motion.button>
                            <motion.button 
                                className="border-4 lg:text-3xl xs:text-2xl text-xl font-bold p-2 bg-[#FFA600] text-black"
                                initial={{ scale: 1.0 }}
                                whileHover={{
                                    scale: 1.05,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.95,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={handleConfirmDeleteCourse}
                            >
                                DELETE
                            </motion.button>
                        </div>
                    </div>
                </div>
            </div>}

            {viewportWidth < 900 && code !== null && <div 
                className="fixed top-0 left-0 bg-[#000000aa] text-6xl text-white h-screen w-screen z-40"
            >
                <div className="w-full h-full flex flex-col justify-around">
                    <div className="bg-[#121220] xl:w-[35%] lg:w-[40%] sm:w-1/2 xs:w-[75%] w-[80%] xs:h-[330px] h-[270px] rounded-2xl border-4 mx-auto flex flex-col justify-between">
                        <p className="font-dosis lg:mt-8 xs:mt-10 mt-6 w-fit mx-auto text-center 2xl:text-5xl lg:text-[2.1rem] mdplus:text-3xl md:text-[1.7rem] sm:text-3xl xs:text-[1.6rem] text-xl px-2">Your code is <span className="font-bold">{code}</span></p>
                        <div className="flex flex-col xs:gap-8 gap-4 justify-between m-8">
                            <motion.button 
                                className="border-4 lg:text-3xl xs:text-2xl text-xl font-bold p-2"
                                initial={{ scale: 1.0 }}
                                whileHover={{
                                    scale: 1.05,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.95,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => { 
                                    setCode(null);
                                }}
                            >
                                OKAY
                            </motion.button>
                            <motion.button 
                                className="border-4 lg:text-3xl xs:text-2xl text-xl font-bold p-2 bg-[#FFA600] text-black "
                                initial={{ scale: 1.0 }}
                                whileHover={{
                                    scale: 1.05,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.95,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => { 
                                    openInNewTab("/info");
                                }}
                            >
                                WHAT'S THIS
                            </motion.button>
                        </div>
                    </div>
                </div>
            </div>}

            {showTooltipsMessage && (
                <div className="fixed top-0 left-0 bg-[#000000aa] h-screen w-screen z-40">
                    <div className="rounded-2xl border-4 mx-auto w-2/3 sm:w-1/2 bg-[#121220] p-8 pb-12 relative top-[25vh]">
                        <p className="text-4xl font-bold text-white font-montserrat w-fit mx-auto mb-4">TIP</p>
                        <div className="border w-[95%] border-[#ffffff50] mx-auto" />
                        <p className="text-sm sm:text-lg mdplus:text-2xl font-montserrat text-white text-center pt-4">You can hover over a button or an input field to see tooltips. </p>
                        <p className="text-sm sm:text-lg mdplus:text-2xl font-montserrat text-white text-center pt-2">On mobile, long-press over a button or an input field to see tooltips.</p>
                        <div className="w-full mx-auto flex flex-row justify-start mt-6">
                            <div className="flex flex-col gap-6 md:gap-0 md:flex-row justify-between w-[95%] mx-auto items-center">
                                <div className="flex flex-row gap-1 items-center">
                                    <img 
                                        src={dontShowAgain ? "/images/checkedBox.svg" : "/images/uncheckedBox.svg"}
                                        alt=""
                                        className="xs:w-[20px] xs:h-[20px] w-[15px] h-[15px] hover:cursor-pointer"
                                        style={{ opacity: dontShowAgain? "1" : "0.5" }}
                                        onClick={handleDontShowAgain}
                                    />
                                    <p className="text-white font-montserrat opacity-50 text-xs xs:text-sm sm:text-base mdplus:text-lg">Don't show again</p>
                                </div>
                                <motion.button 
                                    className="border-4 md:py-1 py-1 xs:py-1.5 px-4 w-full md:w-auto mdplus:py-2 md:px-4 mdplus:px-6 font-montserrat text-black font-bold xs:text-xl text-lg bg-[#FFA600]"
                                    initial={{ scale: 1.0 }}
                                    whileHover={{
                                        scale: 1.05,
                                        transition: {
                                            duration: 0.25
                                        }
                                    }}
                                    whileTap={{
                                        scale: 0.95,
                                        transition: {
                                            duration: 0.125
                                        }
                                    }}
                                    onClick={handleDismissTip}
                                >
                                    OKAY
                                </motion.button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <DashboardNavbar to={`/${lastPage}`} />
            <div className="relative top-[75px] text-white bg-[#121220] min-h-[calc(100vh-75px)]">
                <div className="w-4/5 mx-auto pt-4">

                    {/* HEADER CODE HERE */}
                    <div className="flex md:flex-row flex-col border-b md:items-center justify-between md:h-[75px] sm:h-[100px] xs:h-[90px] h-[85px]">
                        <div className="mdplus:text-5xl md:text-4xl sm:text-5xl xs:text-[2.5rem] text-4xl font-bold md:block flex items-center justify-between">
                            {!editHeader ? (
                                <div className={headerClassName}>
                                    <p>{courseData.name}</p>
                                    {!exists(code) ? (<><motion.button 
                                        className="bg-[url('/public/images/shareIcon.png')] bg-cover w-[20px] h-[20px] sm:w-[30px] sm:h-[30px]"
                                        initial={{ scale: 1.0 }}
                                        whileHover={{
                                            scale: 1.1,
                                            transition: {
                                                duration: 0.25
                                            }
                                        }}
                                        whileTap={{
                                            scale: 0.9,
                                            transition: {
                                                duration: 0.125
                                            }
                                        }}
                                        onClick={() => {
                                            handleShareClick();
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseEnter={(e) => {
                                            startTooltipTimerMouse(e, "shareIcon");
                                        }}
                                        onTouchStart={(e) => {
                                            startTooltipTimerTouch(e, "shareIcon");
                                        }}
                                        onTouchEnd={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                        onMouseLeave={() => {
                                            setHoverToDefault();
                                            setPosToDefault();
                                        }}
                                    />
                                    <AnimatePresence>
                                    {tips.shareIcon &&
                                        <motion.div
                                            key="tooltip"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 1 }}
                                            transition={{ duration: 0.25 }}
                                            exit={{ opacity: 0 }}
                                            className="absolute"
                                        >
                                            <Tip text="Click to share your course" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="right" />
                                        </motion.div>}
                                    </AnimatePresence>
                                    </>) : (
                                    <div className="mdplus:flex hidden flex-row justify-between gap-4 items-center">
                                        <p className="tracking-widest text-[#ffffff60] font-dosis text-lg lg:text-xl font-light">YOUR CODE IS: {code}</p>
                                        <motion.button 
                                            className="bg-[url('/public/images/infoIcon.png')] bg-cover w-[20px] h-[20px]"
                                            whileHover={{
                                                scale: 1.1,
                                                transition: {
                                                    duration: 0.25
                                                }
                                            }}
                                            whileTap={{
                                                scale: 0.9,
                                                transition: {
                                                    duration: 0.125
                                                }
                                            }}
                                            onClick={handleInfoClick}
                                        ></motion.button>
                                    </div>
                                    )}
                                </div>
                            ) : (<>
                                <input
                                    type="text"
                                    className="bg-[#12122000] border-b border-b-[#ffffff50] focus:outline-none mb-2 w-3/4"
                                    autoComplete="off"
                                    onChange={handleEditCourseName}
                                    value={courseName}
                                    onClick={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseEnter={(e) => {
                                        startTooltipTimerMouse(e, "editCourseName");
                                    }}
                                    onTouchStart={(e) => {
                                        startTooltipTimerTouch(e, "editCourseName");
                                    }}
                                    onTouchEnd={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseLeave={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                />
                                <AnimatePresence>
                                {tips.editCourseName &&
                                    <motion.div
                                        key="tooltip"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.25 }}
                                        exit={{ opacity: 0 }}
                                        className="absolute"
                                    >
                                        <Tip text="Click to edit name" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="right" />
                                    </motion.div>}
                                </AnimatePresence>
                                </>
                            )}
                            {!editHeader ? (<><motion.button 
                                className="bg-[url('/public/images/edit.png')] bg-contain bg-center bg-no-repeat xs:w-[50px] w-[40px] h-[40px] xs:h-[50px] block md:hidden"
                                initial={{ scale: 1, opacity: 0.5 }}
                                whileHover={{
                                    scale: 1.1,
                                    opacity: 0.9,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.9,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => {
                                    handleEditHeader();
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseEnter={(e) => {
                                    startTooltipTimerMouse(e, "editHeaderIcon");
                                }}
                                onTouchStart={(e) => {
                                    startTooltipTimerTouch(e, "editHeaderIcon");
                                }}
                                onTouchEnd={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseLeave={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}   
                            />
                            <AnimatePresence>
                            {tips.editHeaderIcon &&
                                <motion.div
                                    key="tooltip"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.25 }}
                                    exit={{ opacity: 0 }}
                                    className="absolute"
                                >
                                    <Tip text="Click to edit" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                </motion.div>}
                            </AnimatePresence>
                            </>) : (<><motion.button 
                                className="bg-[url('/public/images/trashIcon.png')] bg-contain bg-center bg-no-repeat xs:h-[50px] h-[40px] xs:w-[50px] w-[40px] block md:hidden"
                                initial={{ scale: 1, opacity: 0.5 }}
                                whileHover={{
                                    scale: 1.1,
                                    opacity: 0.9,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.9,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => {
                                    handleDeleteCourse();
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseEnter={(e) => {
                                    startTooltipTimerMouse(e, "deleteCourseIcon");
                                }}
                                onTouchStart={(e) => {
                                    startTooltipTimerTouch(e, "deleteCourseIcon");
                                }}
                                onTouchEnd={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseLeave={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                            />
                            <AnimatePresence>
                            {tips.deleteCourseIcon &&
                                <motion.div
                                    key="tooltip"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.25 }}
                                    exit={{ opacity: 0 }}
                                    className="absolute"
                                >
                                    <Tip text="Click to delete course" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                </motion.div>}
                            </AnimatePresence>
                            </>)}
                        </div>
                        <div className="flex flex-row xs:h-full items-center justify-between">
                            {!editHeader ? (<p className="font-dosis tracking-widest md:mx-4 xs:text-xl text-lg">CREDITS: {Number(courseData.credits).toFixed(1)}</p>) : (
                                <><input 
                                    type="text"
                                    name="credits"
                                    autoComplete="off"
                                    placeholder={`CREDITS: ${courseData.credits}`}
                                    onChange={handleHeaderChange}
                                    value={tempCourseData.credits}
                                    className="bg-[#12122000] focus:outline-none font-dosis tracking-widest text-xl border-b mr-6 opacity-70 hover:opacity-100 focus:opacity-100 w-full mb-1"
                                    onClick={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseEnter={(e) => {
                                        startTooltipTimerMouse(e, "editCredits");
                                    }}
                                    onTouchStart={(e) => {
                                        startTooltipTimerTouch(e, "editCredits");
                                    }}
                                    onTouchEnd={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseLeave={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                />
                                <AnimatePresence>
                                {tips.editCredits &&
                                    <motion.div
                                        key="tooltip"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.25 }}
                                        exit={{ opacity: 0 }}
                                        className="absolute"
                                    >
                                        <Tip text="Click to edit credits" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                    </motion.div>}
                                </AnimatePresence>
                                </>
                            )}
                            <div className="border-r border-r-[#ffffff50] w-[1px] md:h-1/3 h-1/2"></div>
                            {!editHeader ? (<p className="font-dosis tracking-widest md:mx-4 xs:text-xl text-lg">TARGET: {courseData.target ? `${courseData.target}%` : "N/A"}</p>) : (
                                <><input
                                    type="text"
                                    name="target"
                                    autoComplete="off"
                                    placeholder={`TARGET: ${courseData.target ? courseData.target : 'N/A'}${courseData.target ? '%' : ''}`}
                                    onChange={handleHeaderChange}
                                    value={tempCourseData.target}
                                    className="bg-[#12122000] focus:outline-none font-dosis tracking-widest text-xl border-b ml-6 md:mr-2 mr-0 opacity-70 hover:opacity-100 focus:opacity-100 w-full mb-1"
                                    onClick={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseEnter={(e) => {
                                        startTooltipTimerMouse(e, "editTarget");
                                    }}
                                    onTouchStart={(e) => {
                                        startTooltipTimerTouch(e, "editTarget");
                                    }}
                                    onTouchEnd={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseLeave={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                />
                                <AnimatePresence>
                                {tips.editTarget &&
                                    <motion.div
                                        key="tooltip"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.25 }}
                                        exit={{ opacity: 0 }}
                                        className="absolute"
                                    >
                                        <Tip text="Click to edit target. Your target is what you are aiming to achieve as a final grade in this course, expressed as a percentage." x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                    </motion.div>}
                                </AnimatePresence>
                                </>
                            )}
                            {!editHeader ? (<motion.button
                                className="bg-contain bg-center bg-no-repeat bg-[url('/public/images/edit.png')] w-[40px] h-[40px] hidden md:block"
                                initial={{ scale: 1, opacity: 0.5 }}
                                whileHover={{
                                    scale: 1.1,
                                    opacity: 0.9,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.9,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => {
                                    handleEditHeader();
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseEnter={(e) => {
                                    startTooltipTimerMouse(e, "editHeaderIcon");
                                }}
                                onTouchStart={(e) => {
                                    startTooltipTimerTouch(e, "editHeaderIcon");
                                }}
                                onTouchEnd={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseLeave={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                            />) : (
                                <motion.button 
                                className="bg-contain bg-center bg-no-repeat bg-[url('/public/images/trashIcon.png')] w-[80px] h-[40px] hidden md:block"
                                initial={{ scale: 1, opacity: 0.5 }}
                                whileHover={{
                                    scale: 1.1,
                                    opacity: 0.9,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.9,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => {
                                    handleDeleteCourse();
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseEnter={(e) => {
                                    startTooltipTimerMouse(e, "deleteCourseIcon");
                                }}
                                onTouchStart={(e) => {
                                    startTooltipTimerTouch(e, "deleteCourseIcon");
                                }}
                                onTouchEnd={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseLeave={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                            />
                            )}
                        </div>
                    </div>

                    {/* SUMMARY OF THE COURSE PAGE REALLY STARTS HERE */}
                    <div className="w-full flex sm:flex-row flex-col pt-16">
                        <div className="sm:w-1/2 w-full">
                            <h1 className="md:text-4xl sm:text-3xl xs:text-4xl text-3xl pb-2 font-bold border-b border-b-[#ffffff50] sm:w-[90%]">Summary</h1>
                            <p className="font-dosis tracking-widest pt-2 md:text-lg sm:text-base sx:text-lg text-base">CURRENT AVERAGE: {avg ? (isNaN(avg) ? "N/A" : formatNumber(Number(avg))) : "N/A"}{avg ? (isNaN(avg) ? "" : "%") : ""}</p>
                            <p className="font-dosis tracking-widest pt-2 md:text-lg sm:text-base sx:text-lg text-base">WEIGHT ACHIEVED: {(exists(courseData.weightAchieved) && exists(courseData.weightPossible)) ? `${formatNumber(courseData.weightAchieved)}/${formatNumber(courseData.weightPossible)}%` : "N/A"}</p>
                            <p 
                                className="font-dosis tracking-widest pt-2 md:text-lg sm:text-base sx:text-lg text-base"
                                onClick={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseEnter={(e) => {
                                    startTooltipTimerMouse(e, "remainingBufferZone");
                                }}
                                onTouchStart={(e) => {
                                    startTooltipTimerTouch(e, "remainingBufferZone");
                                }}
                                onTouchEnd={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                                onMouseLeave={() => {
                                    setHoverToDefault();
                                    setPosToDefault();
                                }}
                            >
                                REMAINING BUFFER ZONE: {`${buffer === false ? "N/A" : buffer}${buffer === false ? '' : '%'}`}
                            </p>
                            <AnimatePresence>
                            {tips.remainingBufferZone &&
                                <motion.div
                                    key="tooltip"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.25 }}
                                    exit={{ opacity: 0 }}
                                    className="absolute"
                                >
                                    <Tip text="This is how many percentage points of the course you can afford to lose to still reach your target" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="right" />
                                </motion.div>}
                            </AnimatePresence>
                        </div>
                        <div className="sm:mt-0 mt-16 sm:w-1/2 w-full flex flex-row sm:justify-end">
                            <div className="sm:w-[90%]">
                                <h1 className="md:text-4xl sm:text-3xl xs:text-4xl text-3xl font-bold pb-2 border-b border-b-[#ffffff50]">Requirement</h1>
                                <p className="font-dosis tracking-widest pt-2 md:text-lg sm:text-base sx:text-lg text-base">REQUIRED AVERAGE ON REMAINING ASSESSMENTS TO HIT TARGET:</p>
                                <p className="font-montserrat font-bold md:text-4xl sm:text-3xl text-4xl w-fit mx-auto mt-4">{(courseData.weightAchieved > courseData.target) ? "0.00%" : requirement ? (isFinite(requirement) ? formatNumber(Number(requirement)) : "💀💀💀") : "N/A"}{requirement ? (isFinite(requirement) ? ((courseData.weightAchieved > courseData.target) ? "" : "%") : "") : ""}</p>
                            </div>
                        </div>
                    </div>

                    {/* BODY OF THE COURSE PAGE REALLY STARTS HERE */}
                    <div className="pt-20 pb-48">
                        <div className="pb-16">
                            {sections}
                        </div>

                        <motion.div 
                            className="flex flex-row w-full border-b border-b-[#ffffff] pb-2"
                            ref={scope}
                            initial={{ opacity: 0.3 }}
                        >
                            {!addNewSection ? (<motion.div 
                                className="flex flex-row justify-between items-center hover:cursor-pointer w-fit relative left-[-10px]"
                                initial={{
                                    scale: 1,
                                }}
                                whileHover={{
                                    scale: 1.05,
                                    transition: {
                                        duration: 0.25
                                    }
                                }}
                                whileTap={{
                                    scale: 0.95,
                                    transition: {
                                        duration: 0.125
                                    }
                                }}
                                onClick={() => handleNewSectionClick()}
                                onMouseEnter={handleAnimateEnter}
                                onMouseLeave={handleAnimateExit}
                            >
                                <img 
                                    src="/images/addButton.png"
                                    alt=""
                                    className="sm:w-[60px] xs:w-[50px] w-[45px]"
                                />
                                <p className="sm:text-4xl xs:text-3xl text-2xl font-dosis xs:tracking-widest tracking-wide font-normal">
                                    ADD NEW SECTION
                                </p>
                                
                            </motion.div>) : (<>
                                <input 
                                    autoComplete="off"
                                    name="name"
                                    placeholder="Section Name"
                                    type="text"
                                    className="bg-[#12122000] focus:outline-none font-montserrat font-bold xs:text-4xl text-2xl opacity-70 hover:opacity-100 focus:opacity-100 w-full"
                                    value={sectionName}
                                    onChange={(event) => handleSectionNameChange(event, courseData.sections ? courseData.sections.length : 0)}
                                    onClick={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseEnter={(e) => {
                                        startTooltipTimerMouse(e, "createSectionName");
                                    }}
                                    onTouchStart={(e) => {
                                        startTooltipTimerTouch(e, "createSectionName");
                                    }}
                                    onTouchEnd={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                    onMouseLeave={() => {
                                        setHoverToDefault();
                                        setPosToDefault();
                                    }}
                                />
                                <AnimatePresence>
                                {tips.createSectionName &&
                                    <motion.div
                                        key="tooltip"
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        transition={{ duration: 0.25 }}
                                        exit={{ opacity: 0 }}
                                        className="absolute"
                                    >
                                        <Tip text="Click to create section name" x={coords[0] ? coords[0] : touchPos[0]} y={coords[1] ? coords[1] : touchPos[1]} type="left" />
                                    </motion.div>}
                                </AnimatePresence>
                                </>
                            )}
                        </motion.div>
                    </div>


                </div>
            </div>

            {/* EDIT MENU POPUP (CANCEL / SAVE) */}
            <AnimatePresence>

                {/* add all preconditions that may turn on the cancel button */}
                {(addNewAssessment.length !== 0 || editHeader || addNewSection || editSection.length !== 0 || editAssessment.length !== 0) && (<motion.div 
                    className="fixed bottom-[50px] xs:w-[300px] w-[250px] xs:left-[calc((50vw)-150px)] left-[calc((50vw)-125px)] rounded-xl p-4 flex flex-row bg-black border-2 border-[#ffffff90] justify-between items-center"
                    initial={{ y: 150, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    transition={{ duration: 1, ease: [0.8, 0, 0.2, 0.85] }}
                    exit={{ y: 150, opacity: 0 }}
                >
                    <motion.button 
                        className="border-4 py-2 font-montserrat font-semibold text-lg w-[47%] bg-[#121220] text-white"
                        initial={{ scale: 1 }}
                        whileHover={{
                            scale: 1.05,
                            transition: {
                                duration: 0.25
                            }
                        }}
                        whileTap={{
                            scale: 0.95,
                            transition: {
                                duration: 0.125
                            }
                        }}
                        onClick={handleCancelEdits}
                    >
                        CANCEL
                    </motion.button>
                    <motion.button 
                        className="border-4 py-2 font-montserrat font-bold text-lg w-[47%] bg-[#FFA600] text-black "
                        initial={{ scale: 1 }}
                        whileHover={{
                            scale: 1.05,
                            transition: {
                                duration: 0.25
                            }
                        }}
                        whileTap={{
                            scale: 0.95,
                            transition: {
                                duration: 0.125
                            }
                        }}
                        onClick={handleSaveEdits}
                    >
                        SAVE
                    </motion.button>
                </motion.div>)}
            </AnimatePresence>

            {/* POPUP WARNING SHTS */}
            <AnimatePresence>
            {popup.visible && (
                <Popup text={popup.text} image={popup.image} />
            )}
            </AnimatePresence>
        </div>
    )
}